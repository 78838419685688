import { createSlice } from '@reduxjs/toolkit';

const gameSlice = createSlice({
    name: 'game',
    initialState: {
        game: {},
        players: null,
        offlinePlayers: null,
        currentPlayer: null,
        currentThrow: null,
        grounds: null,
        groundView: null,
        questionDialog: null,
        sellFor: null,
        logs: [],
        sellOffers: [],
        selectedSellOffer: null,
    },
    reducers: {
        setGame: (state, payload) => {
            state.game = payload.payload;
        },
        setPlayers: (state, payload) => {
            state.players = payload.payload;
        },
        setOfflinePlayers: (state, payload) => {
            state.offlinePlayers = payload.payload;
        },
        setCurrentPlayer: (state, payload) => {
            state.currentPlayer = payload.payload;
        },
        setCurrentThrow: (state, payload) => {
            state.currentThrow = payload.payload;
        },
        setGrounds: (state, payload) => {
            state.grounds = payload.payload;
        },
        setGroundView: (state, payload) => {
            state.groundView = payload.payload;
        },
        setQuestionDialog: (state, payload) => {
            state.questionDialog = payload.payload;
        },
        setSellFor: (state, payload) => {
            state.sellFor = payload.payload;
        },
        setLogs: (state, payload) => {
            state.logs = payload.payload;
        },
        addLog: (state, payload) => {
            state.logs.unshift(payload.payload);
        },
        addSellOffer: (state, payload) => {
            state.sellOffers.push(payload.payload);
        },
        removeSellOffer: (state, payload) => {
            state.sellOffers = state.sellOffers.filter(
                (sellOffer) => sellOffer.id !== payload.payload.id
            );
        },
        setSelectedSellOffer: (state, payload) => {
            state.selectedSellOffer = payload.payload;
        },
    },
});

export const {
    setGame,
    setPlayers,
    setCurrentPlayer,
    setCurrentThrow,
    setGrounds,
    setGroundView,
    setQuestionDialog,
    setSellFor,
    setLogs,
    addLog,
    setOfflinePlayers,
    addSellOffer,
    setSelectedSellOffer,
    removeSellOffer,
} = gameSlice.actions;

export default gameSlice.reducer;

export const selectGame = (state) => state.game.game;
export const selectPlayers = (state) => state.game.players;
export const selectOfflinePlayers = (state) => state.game.offlinePlayers;
export const selectCurrentPlayer = (state) => state.game.currentPlayer;
export const selectCurrentThrow = (state) => state.game.currentThrow;
export const selectGrounds = (state) => state.game.grounds;
export const selectGroundView = (state) => state.game.groundView;
export const selectQuestionDialog = (state) => state.game.questionDialog;
export const selectSellFor = (state) => state.game.sellFor;
export const selectLogs = (state) => state.game.logs;
export const selectSellOffers = (state) => state.game.sellOffers;
export const selectSelectedSellOffer = (state) => state.game.selectedSellOffer;
