import {
    Avatar,
    Card,
    Grid,
    Icon,
    Paper,
    TextField,
    Typography,
    Button,
    Tooltip,
} from '@mui/material';
import { Color3 } from '@babylonjs/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectLobbyId,
    selectSocketByNamespace,
    selectSocketLoaded,
    setupSockets,
    socketListener,
} from '../store/socketSlice';
import { selectUser, setupAuth } from '../store/authSlice';
import LobbySettings, { settingsConfig } from './LobbySettings';
import Logo from '../ui-components/Logo';
import clsx from 'clsx';
import Box from '../ui-components/Box';
import LobbyOwnSettings from './LobbyOwnSettings';
import gameType from '../gameType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Lobby({ gameMode }) {
    const dispatch = useDispatch();
    const socket = useSelector((state) => selectSocketByNamespace(state, gameMode.toLowerCase()));
    const socketLoaded = useSelector(selectSocketLoaded);
    const user = useSelector(selectUser);
    const [hideLink, setHideLink] = useState(false);
    const [game, setGame] = useState(null);
    const [ownSettingsDialogOpen, setOwnSettingsDialogOpen] = useState(false);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [players, setPlayers] = useState([
        // { color: 'red', gameColor: Color3.Red() },
        // { color: 'blue', gameColor: Color3.Blue() },
        // {
        //     color: 'green',
        //     gameColor: Color3.Green(),
        // },
        // { color: 'yellow', gameColor: Color3.Yellow() },
        // { color: 'purple', gameColor: Color3.Purple() },
        // {
        //     color: 'white',
        //     gameColor: Color3.White(),
        //     textColor: 'white',
        // },
    ]);
    const navigate = useNavigate();
    const { lobbyId } = useParams();
    const once = useRef(false);
    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);
    const lobbyIdFromIO = useSelector(selectLobbyId);

    const handlePlayers = useCallback((response) => {
        setPlayers(response.players);
    }, []);

    const handleStart = useCallback(
        (response) => {
            navigate('/game/' + response.gameId);
        },
        [navigate]
    );

    const handleGame = useCallback(
        (response) => {
            setGame(response.game);
            if (response.game.state === 'STARTED') {
                navigate('/game/' + response.lobbyId);
            } else if (response.game.state === 'ENDED') {
                navigate('/end/' + response.lobbyId);
            }
        },
        [navigate]
    );

    const handleUserUpdated = useCallback(
        (response) => {
            window.localStorage.setItem('jwt_access_token', response.token);
            dispatch(setupAuth());
        },
        [dispatch]
    );

    useEffect(() => {
        if (lobbyId && lobbyIdFromIO && lobbyIdFromIO !== lobbyId) {
            window.location.reload();
        }
    }, [lobbyId, lobbyIdFromIO]);

    useEffect(() => {
        if (lobbyId && !once.current) {
            once.current = true;
            dispatch(setupSockets(lobbyId, gameMode.toLowerCase()));
        }
    }, [dispatch, gameMode, lobbyId]);

    useEffect(() => {
        if (!user?.discordUser?.id && lobbyId) {
            apiService
                .request('/game/' + gameMode.toLowerCase() + '/game/' + lobbyId)
                .then((response) => {
                    setPlayers(response.players);
                });
        }
    }, [gameMode, lobbyId, user]);

    useEffect(() => {
        if (socketLoaded) {
            const successUnsubListener = dispatch(
                socketListener(gameMode.toLowerCase(), 'success', (message) => {
                    console.log('message', message);
                })
            );
            const playersUnsubListener = dispatch(
                socketListener(gameMode.toLowerCase(), 'players', handlePlayers)
            );
            const startUnsubListener = dispatch(
                socketListener(gameMode.toLowerCase(), 'start', handleStart)
            );
            const gameUnsubListener = dispatch(
                socketListener(gameMode.toLowerCase(), 'game', handleGame)
            );
            const userUpdateUnsubListener = dispatch(
                socketListener(gameMode.toLowerCase(), 'user-updated', handleUserUpdated)
            );
            return () => {
                successUnsubListener();
                playersUnsubListener();
                startUnsubListener();
                gameUnsubListener();
                userUpdateUnsubListener();
            };
        }
        return () => {};
    }, [
        dispatch,
        socketLoaded,
        handlePlayers,
        handleStart,
        handleGame,
        gameMode,
        handleUserUpdated,
    ]);

    console.log({ game });

    async function handleCreate() {
        if (user?.discordUser || gameMode === gameType.UNO) {
            if (gameMode === gameType.UNO && !user?.discordUser) {
                await apiService.loginAnonymously();
            }
            if (lobbyId) {
                window.location.reload();
            } else {
                const response = await apiService.request(
                    '/game/' + gameMode.toLowerCase() + '/create',
                    'POST',
                    {
                        password: null,
                    }
                );
                navigate('/lobby/' + response.lobbyId);
            }
        } else {
            navigate(lobbyId ? '/login?id=' + lobbyId : '/login');
        }
    }

    function handleReady() {
        socket.emit('set-ready', !playerSelf?.ready);
    }

    async function handleCloseOwnSettings(data) {
        if (data) {
            socket.emit('update-user', data);
        }
        setOwnSettingsDialogOpen(false);
    }

    async function handleCloseSettings(data) {
        if (data) {
            socket.emit('update-game', data);
        }
        setSettingsDialogOpen(false);
    }

    function handleOpenOwnSettings() {
        setOwnSettingsDialogOpen(true);
    }

    function handleOpenSettings() {
        setSettingsDialogOpen(true);
    }

    return (
        <div className={'bg-gray-900 py-10 w-screen h-screen'}>
            {players.length <= 0 && (
                <div className={'flex justify-center items-center h-full'}>
                    <div className={'flex flex-col items-center justify-center'}>
                        <div className={'flex items-center mb-20'}>
                            <Logo />
                        </div>
                        <Button
                            variant="contained"
                            className={'px-24 py-8 text-lg'}
                            onClick={handleCreate}
                        >
                            {gameMode === gameType.MATADOR ? 'Opret lobby' : 'Create lobby'}
                        </Button>
                    </div>
                </div>
            )}
            {players.length > 0 && (
                <>
                    <div className={'flex justify-center py-10'}>
                        <Logo />
                    </div>
                    <div className={'max-w-4xl m-auto'}>
                        <Grid container spacing={2}>
                            {players.map((player) => (
                                <Grid item xs={6}>
                                    <Card
                                        key={player.id}
                                        className={clsx(
                                            'p-6 rounded-lg border-2 border-solid relative overflow-hidden',
                                            player.ready ? 'border-green-400' : 'border-red-400'
                                        )}
                                    >
                                        {player.id === playerSelf?.id && (
                                            <div
                                                className={'bg-green-200 absolute w-72 text-center'}
                                                style={{
                                                    transform: 'rotate(40deg)',
                                                    right: '-2.4rem',
                                                    top: '0.5rem',
                                                }}
                                            >
                                                <Typography className={'text-green-800'}>
                                                    you
                                                </Typography>
                                            </div>
                                        )}
                                        <div className={'flex items-center p-6'}>
                                            <Avatar
                                                src={
                                                    player.avatar
                                                        ? `https://cdn.discordapp.com/avatars/${player.user_id}/${player.avatar}.png`
                                                        : `https://cdn.discordapp.com/embed/avatars/${
                                                              player.discriminator % 5
                                                          }.png`
                                                }
                                                className={'w-32 h-32'}
                                            />
                                            <Typography
                                                className={'ml-6 font-bold text-xl text-white'}
                                            >
                                                {player.username}
                                            </Typography>
                                        </div>
                                    </Card>
                                </Grid>
                            ))}
                            {players.length > 0 && !playerSelf && (
                                <Grid item xs={6}>
                                    <Card
                                        className={clsx(
                                            'p-6 rounded-lg border-2 border-solid cursor-pointer'
                                        )}
                                        onClick={handleCreate}
                                    >
                                        <div className={'flex items-center p-6'}>
                                            <Avatar className={'w-32 h-32'}>
                                                <Icon>add</Icon>
                                            </Avatar>
                                            <Typography
                                                className={'ml-6 font-bold text-xl text-white'}
                                            >
                                                Klik for at spille med
                                            </Typography>
                                        </div>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                        {players.length > 0 && (
                            <div className={'flex justify-center mt-16 w-full relative'}>
                                {!hideLink && (
                                    <div
                                        onClick={() => setHideLink((oldState) => !oldState)}
                                        className={
                                            'absolute top-0 left-0 w-full h-full cursor-pointer'
                                        }
                                        style={{
                                            backdropFilter: 'blur(4px)',
                                            backgroundColor: 'transparent',
                                            zIndex: 999,
                                        }}
                                    >
                                        <div className={'flex items-center justify-center h-full'}>
                                            <Typography className={'text-white font-bold'}>
                                                {gameMode === gameType.MATADOR
                                                    ? 'Klik for at vise link'
                                                    : 'Click to show link'}
                                            </Typography>
                                        </div>
                                    </div>
                                )}
                                <TextField
                                    color={'primary'}
                                    className={'w-full'}
                                    variant={'outlined'}
                                    value={window.location.origin + '/lobby/' + lobbyId}
                                />
                            </div>
                        )}
                        <div className={'flex justify-around mt-32'}>
                            {playerSelf?.owner && (
                                <>
                                    {settingsDialogOpen && (
                                        <LobbySettings
                                            onClose={handleCloseSettings}
                                            defaultValues={game || {}}
                                        />
                                    )}
                                    {gameMode === gameType.UNO && (
                                        <Button
                                            disabled={players.length === 0}
                                            color={'warning'}
                                            variant={'contained'}
                                            className={'py-8 text-lg w-136'}
                                            onClick={handleOpenSettings}
                                        >
                                            GAME SETTINGS
                                        </Button>
                                    )}
                                </>
                            )}
                            {ownSettingsDialogOpen && (
                                <LobbyOwnSettings
                                    onClose={handleCloseOwnSettings}
                                    defaultValues={user}
                                />
                            )}
                            {gameMode === gameType.UNO && (
                                <Button
                                    color={'info'}
                                    variant={'contained'}
                                    className={'w-136 py-8 text-lg'}
                                    onClick={handleOpenOwnSettings}
                                >
                                    {gameMode === gameType.MATADOR
                                        ? 'BRUGER INDSTILLINGER'
                                        : 'USER SETTINGS'}
                                </Button>
                            )}
                            <Button
                                disabled={players.length <= 1}
                                variant={'contained'}
                                className={'w-136 py-8 text-lg'}
                                color={playerSelf?.ready ? 'success' : 'error'}
                                onClick={handleReady}
                            >
                                {gameMode === gameType.MATADOR && (
                                    <>{playerSelf?.ready ? 'JEG ER KLAR' : 'MARKÉR SOM KLAR'}</>
                                )}
                                {gameMode === gameType.UNO && (
                                    <>{playerSelf?.ready ? "I'M READY" : 'MARK AS READY'}</>
                                )}
                            </Button>
                        </div>
                        <Typography className={'text-white text-center mt-20 mb-8'}>
                            Game Settings
                        </Typography>
                        <div className={'flex items-center justify-center'}>
                            {Object.keys(settingsConfig).map((key) => {
                                const config = settingsConfig[key];
                                return (
                                    <Tooltip
                                        title={config.title}
                                        className={clsx(
                                            'text-white mx-10 w-40 flex items-center justify-center',
                                            !game?.settings?.[key] && 'opacity-20'
                                        )}
                                    >
                                        {config.icon instanceof Array && (
                                            <FontAwesomeIcon
                                                icon={config.icon}
                                                size={'3x'}
                                                rotation={config.iconRotation}
                                            />
                                        )}
                                        {!(config.icon instanceof Array) && (
                                            <Icon fontSize={'inherit'} className={'text-5xl'}>
                                                {config.icon}
                                            </Icon>
                                        )}
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Lobby;
