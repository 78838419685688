import {
    Scene,
    Mesh,
    Vector3,
    Color3,
    TransformNode,
    SceneLoader,
    ParticleSystem,
    Color4,
    Texture,
    PBRMetallicRoughnessMaterial,
    VertexBuffer,
    AnimationGroup,
    Sound,
    ExecuteCodeAction,
    ActionManager,
    Tags,
    ArcRotateCamera,
    HemisphericLight,
    MeshBuilder,
    Ray,
} from '@babylonjs/core';
import * as BABYLON from '@babylonjs/core';
import apiService from '../../services/apiService';

export class Environment {
    _gameId;
    _scene;
    _socket;
    _cars;
    _colors;
    _camera;
    _ground;
    _game;
    _onGroundClick;

    constructor(gameId, scene, socket, onGroundClick) {
        this._gameId = gameId;
        this._scene = scene;
        this._socket = socket;
        this._cars = {};
        this._colors = {};
        this._camera = null;
        this._ground = null;
        this._game = null;
        this._onGroundClick = onGroundClick;
    }
    //What we do once the environment assets have been imported
    //handles setting the necessary flags for collision and trigger meshes,
    //sets up the lantern objects
    //creates the firework particle systems for end-game
    async load() {
        this._scene.clearColor = new BABYLON.Color3(0.063, 0.094, 0.122);

        this._camera = new ArcRotateCamera('camera1', 0, 0, 10, new Vector3(0, 0, 0));
        this._camera.setPosition(new Vector3(0, 55, 0));
        this._camera.lowerRadiusLimit = 25;
        this._camera.upperRadiusLimit = 55;
        this._camera.lowerBetaLimit = 0.1;
        this._camera.upperBetaLimit = (Math.PI / 2) * 0.99;

        // This targets the camera to scene origin
        this._camera.setTarget(Vector3.Zero());

        const canvas = this._scene.getEngine().getRenderingCanvas();

        // This attaches the camera to the canvas
        this._camera.attachControl(canvas, true);

        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        const light = new HemisphericLight('light', new Vector3(0, 10, 0), this._scene);

        // Default intensity is 1. Let's dim the light a small amount
        light.intensity = 0.7;
        await this._loadColors();
        await this._loadAssets();

        this._game = await apiService.request('/game/matador/game/' + this._gameId);

        let colorsNotTaken = ['RED', 'BLUE', 'GREEN', 'YELLOW', 'PINK', 'WHITE'];
        this._game.players.forEach((player) => {
            this._cars[player.color.toUpperCase()].rotateAround(
                new BABYLON.Vector3(0, 0, 0),
                BABYLON.Axis.Y,
                (Math.PI / 180) * 9 * player.ground
            );
            colorsNotTaken = colorsNotTaken.filter((color) => color !== player.color.toUpperCase());
        });
        this._game.grounds.forEach((ground) => {
            this._setGroundColor(ground, ground.color);
        });

        // setInterval(() => {
        //     Object.values(this._cars).forEach((car) =>
        //         car.rotateAround(
        //             new BABYLON.Vector3(0, 0, 0),
        //             BABYLON.Axis.Y,
        //             (Math.PI / 180) * 9 * 1
        //         )
        //     );
        //        }, 500);

        colorsNotTaken.forEach((color) => {
            this._cars[color].dispose();
        });

        await this._setupSocketListeners();
        // await this._setGroundColor({ meshId: 'Cylinder.066_primitive0' }, 'red');
    }

    async _setupSocketListeners() {
        this._socket.on('current-throw', (response) => {
            console.log('current-throw', response);
            if (!response) {
                return;
            }
            const { currentPlayer, oldGround, newGround } = response;
            setTimeout(() => {
                this._movePlayerToGround(currentPlayer.toUpperCase(), oldGround, newGround);
            }, 1600);
        });
        this._socket.on('ground-update', (response) => {
            console.log('ground-update', response);
            if (!response) {
                return;
            }
            Promise.all(
                response.grounds.map((ground) =>
                    this._setGroundColor(
                        ground.ground,
                        ground.type === 'buy' ? ground.player.color : 'black'
                    )
                )
            );
        });
    }

    async _setGroundColor(ground, color) {
        const _mesh = this._ground.meshes.find((m) => m.id === ground.meshId);
        _mesh.material = this._colors[color.toUpperCase()];
        // this._ground.meshes.forEach((mesh, index) => {
        //     if (index % 2 === 1) {
        //         console.log(mesh.id);
        //     }
        // });
    }

    async _movePlayerToGround(color, oldGround, ground) {
        let newValue;
        if (oldGround > ground) {
            newValue = (Math.PI / 180) * 9 * (40 - oldGround + ground);
        } else {
            newValue = (Math.PI / 180) * 9 * (ground - oldGround);
        }
        this._cars[color].rotateAround(new BABYLON.Vector3(0, 0, 0), BABYLON.Axis.Y, newValue);
        // const animation = new BABYLON.Animation(
        //     'rotateAnimation',
        //     'rotationQuaternion.x',
        //     30,
        //     BABYLON.Animation.ANIMATIONTYPE_FLOAT,
        //     BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
        // );
        // animation.setKeys([
        //     { frame: 0, value: 0 },
        //     { frame: 30, value: newValue },
        // ]);
        // this._cars[color].animations = [];
        // this._cars[color].animations.push(animation);
        // this._scene.beginAnimation(this._cars[color], 0, 30, false);
    }

    //Load all necessary colors for the environment
    async _loadColors() {
        const materialRed = new BABYLON.StandardMaterial('material', this._scene);
        materialRed.diffuseColor = BABYLON.Color3.FromHexString('#ff0000');
        const materialOrange = new BABYLON.StandardMaterial('material', this._scene);
        materialOrange.diffuseColor = BABYLON.Color3.FromHexString('#ff9100');
        const materialGreen = new BABYLON.StandardMaterial('material', this._scene);
        materialGreen.diffuseColor = BABYLON.Color3.FromHexString('#08ff00');
        const materialGray = new BABYLON.StandardMaterial('material', this._scene);
        materialGray.diffuseColor = BABYLON.Color3.FromHexString('#656565');
        const materialDarkGray = new BABYLON.StandardMaterial('material', this._scene);
        materialDarkGray.diffuseColor = BABYLON.Color3.FromHexString('#3d3d3d');
        const materialPink = new BABYLON.StandardMaterial('material', this._scene);
        materialPink.diffuseColor = BABYLON.Color3.FromHexString('#ff69b4');
        const materialYellow = new BABYLON.StandardMaterial('material', this._scene);
        materialYellow.diffuseColor = BABYLON.Color3.FromHexString('#ffef00');
        const materialLightBlue = new BABYLON.StandardMaterial('material', this._scene);
        materialLightBlue.diffuseColor = BABYLON.Color3.FromHexString('#00bbff');
        const materialPurple = new BABYLON.StandardMaterial('material', this._scene);
        materialPurple.diffuseColor = BABYLON.Color3.FromHexString('#8c00ff');
        const materialBlue = new BABYLON.StandardMaterial('material', this._scene);
        materialBlue.diffuseColor = BABYLON.Color3.FromHexString('#000dff');
        const materialBlack = new BABYLON.StandardMaterial('material', this._scene);
        materialBlack.diffuseColor = BABYLON.Color3.FromHexString('#000000');
        const materialLightRed = new BABYLON.StandardMaterial('material', this._scene);
        materialLightRed.diffuseColor = BABYLON.Color3.FromHexString('#ff4e4e');
        this._colors = {
            BLUE: materialBlue,
            ORANGE: materialOrange,
            GREEN: materialGreen,
            GRAY: materialGray,
            RED: materialRed,
            LIGHT_RED: materialLightRed,
            PINK: materialPink,
            YELLOW: materialYellow,
            LIGHT_BLUE: materialLightBlue,
            PURPLE: materialPurple,
            DARK_GRAY: materialDarkGray,
            BLACK: materialBlack,
        };
    }

    //Load all necessary meshes for the environment
    async _loadAssets() {
        // this._ground = BABYLON.MeshBuilder.CreateCylinder(
        //     'ground',
        //     { height: 0.01, diameter: 35, tessellation: 64 },
        //     this._scene
        // );
        // this._ground.enableEdgesRendering();
        // this._ground.edgesWidth = 1;
        // this._ground.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        // this._ground.material = this._colors.LIGHT_BLUE;
        //
        // const grounds = await apiService.request('/game/matador/grounds').then((resp) => {
        //     return resp.grounds;
        // });
        // for (let i = 0; i < 40; i++) {
        //     const ground = grounds[i];
        //
        //     const meshUpperGround = BABYLON.MeshBuilder.CreateCylinder(
        //         'upperground-' + ground.number,
        //         {
        //             height: 0.001,
        //             diameter: 46,
        //             tessellation: 64,
        //             enclose: true,
        //             arc: 0.0251,
        //         },
        //         this._scene
        //     );
        //     if (this._colors[ground.color]) {
        //         meshUpperGround.material = this._colors[ground.color];
        //     } else if (ground.special === 'QUESTION') {
        //         meshUpperGround.material = this._colors.DARK_GRAY;
        //     } else if (['JAIL', 'JAILVISIT'].includes(ground.special)) {
        //         meshUpperGround.material = this._colors.BLACK;
        //     } else if (ground.special === 'START') {
        //         meshUpperGround.material = this._colors.LIGHT_RED;
        //     } else if (ground.special === 'TAX') {
        //         meshUpperGround.material = this._colors.PINK;
        //     } else if (ground.special === 'TAX2') {
        //         meshUpperGround.material = this._colors.PINK;
        //     } else if (ground.special === 'PARK') {
        //         meshUpperGround.material = this._colors.BLUE;
        //     }
        //     meshUpperGround.checkCollisions = false;
        //     meshUpperGround.isPickable = false;
        //     meshUpperGround.rotation.y = 0.15705 * i;
        //
        //     const meshLowerGround = BABYLON.MeshBuilder.CreateCylinder(
        //         'lowerground-' + ground.number,
        //         {
        //             height: 0.002,
        //             diameter: 44,
        //             tessellation: 64,
        //             enclose: true,
        //             arc: 0.0251,
        //         },
        //         this._scene
        //     );
        //     meshLowerGround.rotation.y = 0.15705 * i;
        //     meshLowerGround.enableEdgesRendering();
        //     meshLowerGround.edgesWidth = 0.5;
        //     meshLowerGround.edgesColor = new BABYLON.Color4(0, 0, 0, 1);
        //
        //     // const text = BABYLON.MeshBuilder.CreateText(
        //     //     'groundtext-' + ground.number,
        //     //     'Hello World !! @ #$ % é',
        //     //     fontData,
        //     //     {
        //     //         size: 16,
        //     //         resolution: 64,
        //     //         depth: 10,
        //     //     },
        //     //     this._scene
        //     // );
        // }
        this._ground = await BABYLON.SceneLoader.ImportMeshAsync(
            null,
            '/assets/',
            'board.glb',
            this._scene
        );
        const groundMesh = this._ground.meshes[0];
        groundMesh.scaling = new BABYLON.Vector3(28, 28, -28);
        groundMesh.rotation = new BABYLON.Vector3(Math.PI, 0, 0); // 4.85
        groundMesh.position = new BABYLON.Vector3(0.1, -9, -0.3);

        // MeshBuilder.CreateGround('ground', { width: 10, height: 10, updatable: true }, this._scene);

        this._scene.onPointerDown = () => {
            const ray = this._scene.createPickingRay(
                this._scene.pointerX,
                this._scene.pointerY,
                BABYLON.Matrix.Identity(),
                this._camera
            );

            const hit = this._scene.pickWithRay(ray);
            if (hit.pickedMesh) {
                this._onGroundClick(hit.pickedMesh.name);
            }
        };

        const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync(
            null,
            '/assets/',
            'car.glb',
            this._scene
        );
        const carMesh = meshes[0];

        Object.entries({
            RED: this._colors.RED,
            BLUE: this._colors.BLUE,
            GREEN: this._colors.GREEN,
            YELLOW: this._colors.YELLOW,
            PINK: this._colors.PINK,
            WHITE: null,
        }).forEach(([key, color], index) => {
            const car = carMesh.clone(key + 'Car'); //
            car.scaling = new BABYLON.Vector3(0.6, 0.6, 0.6);
            car.setPositionWithLocalVector(new Vector3(0, 2.2, 0));
            car.position.x = 13.5 + index * 0.9;
            // car.position.x = 11.5 + index * 0.9;
            car.setPivotPoint(new BABYLON.Vector3(0, 0, 0));
            car.getChildMeshes().forEach((childMesh, index) => {
                if (childMesh.name.endsWith('Object_6')) {
                    childMesh.material = color;
                }
            });
            car.rotateAround(new BABYLON.Vector3(0, 0, 0), BABYLON.Axis.Y, 0.58);
            this._cars[key] = car;
        });
        carMesh.dispose();
    }
}
