import {
    Alert,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import _ from 'lodash';
import clsx from 'clsx';

const numberFormatter = new Intl.NumberFormat('da-DK');

export const settingsConfig = {
    allowMultiplePlusCards: {
        icon: 'style',
        title: 'Allow multiple +2/+4 cards',
        description: 'Allow multiple +2/+4 cards to be played on each other',
        defaultValuePath: 'settings.allowMultiplePlusCards',
    },
    allowJumpIn: {
        icon: ['fas', 'fa-arrow-rotate-right'],
        iconRotation: '90',
        title: 'Allow jump in',
        description:
            'Allow any player at any time to play a card if they have the same exact card as the top card',
        defaultValuePath: 'settings.allowJumpIn',
        disabledIf: ['allowJumpInWithTimeout'],
    },
    allowJumpInWithTimeout: {
        icon: ['fas', 'fa-arrow-rotate-right'],
        iconRotation: '90',
        title: 'Allow jump in with timeout',
        description:
            'Allow any player, within 5 seconds of a played card, to play a card if they have the exact same card as the top card',
        defaultValuePath: 'settings.allowJumpInWithTimeout',
        disabledIf: ['allowJumpIn'],
    },
    allowSameCardNumberAndColor: {
        icon: ['fas', 'fa-palette'],
        title: 'Allow multiple cards in same turn - Number and color',
        description:
            'Allow same card to be played after each other in same turn - Number and color must be the same',
        defaultValuePath: 'settings.allowSameCardNumberAndColor',
        disabledIf: ['allowSameCardOnlyNumber'],
    },
    allowSameCardOnlyNumber: {
        icon: ['fas', 'fa-1'],
        title: 'Allow multiple cards in same turn - Number only',
        description:
            'Allow same card to be played after each other in same turn - Number must be the same',
        defaultValuePath: 'settings.allowSameCardOnlyNumber',
        disabledIf: ['allowSameCardNumberAndColor'],
    },
    undoOption: {
        icon: ['fas', 'fa-rotate-left'],
        title: 'Undo option',
        description: 'Give the option to undo a move until the player ends their turn',
        defaultValuePath: 'settings.allowJumpIn',
    },
    // skipBounce: {
    //     icon: ['fas', 'fa-arrow-rotate-right'],
    //     iconRotation: '90',
    //     title: 'Skip Bounce',
    //     description:
    //         'If a player plays a +2 card, the next player can play a skip card in the same color, to make the next player draw the cards instead',
    //     defaultValuePath: 'settings.skipBounce',
    // },
};
// const settingsConfig = {
//     buyGroundFirstRound: {
//         icon: ['fas', 'fa-circle-notch'],
//         title: 'Køb af grund i runde 1',
//         description: 'Tillad køb af grunde i runde 1',
//     },
//     payAtJailEnd: {
//         icon: ['fas', 'fa-handcuffs'],
//         title: 'Betal ved løsladelse',
//         description: 'Betal for at komme ud af fængsel når de 3 omgange er gået',
//     },
//     // auctionOnBankrupt: {
//     //     icon: ['fas', 'fa-gavel'],
//     //     title: 'Auktion ved konkurs',
//     //     description: 'Auktionér grunde når en spiller går konkurs',
//     // },
//     // auctionOnNoMoney: {
//     //     icon: ['fas', 'fa-gavel'],
//     //     title: 'Auktion ved manglende penge',
//     //     description: 'Auktionér grunde når en spiller ikke har råd til at købe en grund',
//     // },
//     sellToOtherPlayers: {
//         icon: ['fas', 'fa-money-bill-transfer'],
//         title: 'Sælg til andre spillere',
//         description: 'Tillad at sælge grunde til andre spillere',
//         default: true,
//         // disabledIf: ['sellToOtherPlayersAfterAllGroundsAreSold'],
//     },
//     // sellToOtherPlayersAfterAllGroundsAreSold: {
//     //     icon: ['fas', 'fa-hand-holding-usd'],
//     //     title: 'Sælg til andre spillere',
//     //     description:
//     //         'Tillad at sælge grunde til andre spillere, men først efter alle grunde er solgt',
//     //     default: true,
//     //     disabledIf: ['sellToOtherPlayers'],
//     // },
//     // moneyOverStart: {
//     //     icon: ['fas', 'fa-money-bill-wave'],
//     //     title: 'Ændret antal penge over start',
//     //     description: 'Vælg en brugerdefineret værdi for antal penge over start end 4000',
//     // },
//     parkingMoney: {
//         icon: ['fas', 'fa-parking'],
//         title: 'Parkering penge',
//         description: 'Alle penge fra skatter og afgifter går i parkerings puljen',
//         default: true,
//     },
//     autoCollect: {
//         icon: ['fas', 'fa-hand-holding-usd'],
//         title: 'Automatisk indsamling',
//         description: 'Automatisk indsamling af leje fra grunde man ejer',
//     },
//     hideGroundOwner: {
//         icon: ['fas', 'fa-user-secret'],
//         title: 'Skjul grund ejer',
//         description: 'Skjul grund ejer for andre spillere',
//     },
//     punishFakeCollect: {
//         icon: ['fas', 'fa-user-secret'],
//         title: 'Straf for ukorrekt opkrævning',
//         description: 'Giv en bøde til en spiller der opkræver leje for en grund de ikke ejer',
//     },
// };

export default function LobbySettings({ onClose, defaultValues }) {
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const _settings = {};
        Object.entries(settingsConfig).forEach(([id, settingData]) => {
            _settings[id] =
                _.get(defaultValues, settingData.defaultValuePath) ?? settingData.default ?? false;
        });
        setSettings(_settings);
    }, [defaultValues]);

    console.log({ defaultValues });

    const disabledSettings = React.useMemo(() => {
        return Object.entries(settingsConfig).reduce((acc, [id, settingData]) => {
            if (settingData.disabledIf) {
                const disabled = settingData.disabledIf.some(
                    (disabledId) => settings?.[disabledId] === true
                );
                if (disabled) {
                    acc.push(id);
                }
            }
            return acc;
        }, []);
    }, [settings]);

    const disabled = React.useMemo(() => {
        return false;
    }, []);

    async function handleClose(save) {
        if (save) {
            setLoading(true);
            await onClose(settings);
            setLoading(false);
        } else {
            onClose();
        }
    }

    function toggleSetting(id) {
        setSettings((oldState) => {
            return {
                ...oldState,
                [id]: !oldState[id],
            };
        });
    }

    return (
        <Dialog open onClose={() => handleClose(false)} maxWidth={'xl'} fullWidth>
            <DialogTitle className={'text-center'}>Game Settings</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {Object.entries(settingsConfig).map(([id, settingData]) => (
                        <Grid item xs={4} key={'settings-' + id}>
                            <Card>
                                <CardContent className={'pb-10 flex items-center h-72'}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!settings?.[id]}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                            />
                                        }
                                        onChange={() => toggleSetting(id)}
                                        disabled={disabledSettings.includes(id)}
                                        label={
                                            <div className={'flex items-center'}>
                                                <div
                                                    className={
                                                        'w-32 flex justify-center mr-10 ml-4'
                                                    }
                                                >
                                                    {settingData.icon instanceof Array && (
                                                        <FontAwesomeIcon
                                                            icon={settingData.icon}
                                                            size={'3x'}
                                                            rotation={settingData.iconRotation}
                                                        />
                                                    )}
                                                    {!(settingData.icon instanceof Array) && (
                                                        <Icon
                                                            fontSize={'inherit'}
                                                            className={clsx('text-5xl')}
                                                        >
                                                            {settingData.icon}
                                                        </Icon>
                                                    )}
                                                </div>
                                                <div>
                                                    <Typography variant={'h6'}>
                                                        {settingData.title}
                                                    </Typography>
                                                    <Typography variant={'body2'}>
                                                        {settingData.description}
                                                    </Typography>
                                                </div>
                                            </div>
                                        }
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                {/*{disabled === 'money' && (*/}
                {/*    <Alert variant={'filled'} severity={'error'} className={'mr-10 py-0'}>*/}
                {/*        Du har ikke råd til at købe dette.*/}
                {/*    </Alert>*/}
                {/*)}*/}
                <LoadingButton
                    onClick={() => handleClose(true)}
                    variant={'contained'}
                    disabled={!!disabled}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
