import gameType from './gameType';

export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getGameType() {
    let gameMode = window.location.hostname === 'matadoren.dk' ? gameType.MATADOR : gameType.UNO;
    if (window.location.hostname === 'localhost') {
        gameMode = gameType.UNO;
    }
    return gameMode;
}

export function getRandomLetter() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    return characters.charAt(Math.floor(Math.random() * charactersLength));
}

export function translateColor(color) {
    switch (color.toLowerCase()) {
        case 'light_blue':
            return 'Lyseblå';
        case 'blue':
            return 'Blå';
        case 'yellow':
            return 'Gul';
        case 'gray':
            return 'Grå';
        case 'red':
            return 'Rød';
        case 'green':
            return 'Grøn';
        case 'purple':
            return 'Lilla';
        case 'white':
            return 'Hvid';
        case 'orange':
            return 'Orange';
        default:
            return 'Ukendt';
    }
}
