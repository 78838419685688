import React from 'react';
import { styled } from 'styled-components';
import { getGameType } from '../utilities';
import gameType from '../gameType';

const Title = styled.p`
    font-size: 150px;
    line-height: 0;
    color: #fff;
    text-shadow: -1px 5px 0 rgba(69, 248, 130, 0.66);
    font-family: 'berlin_sans_fb_demibold';
`;

export default function Logo({ className }) {
    const gameMode = getGameType();
    return (
        <div className={'flex items-center'}>
            {gameMode === gameType.MATADOR && <Title>Matador</Title>}
            {gameMode === gameType.UNO && <Title>UNO</Title>}
        </div>
    );
}
