import { useSelector } from 'react-redux';
import { selectGame } from './gameSlice';
import { animated, useSpring } from '@react-spring/web';
import UnoCard from './UnoCard';
import { useEffect, useRef } from 'react';

export default function CurrentCard() {
    const game = useSelector(selectGame);
    const oldCard = useRef(null);

    const [props, api] = useSpring(
        () => ({
            from: { transform: 'translateY(-200px)' },
            to: { transform: 'translateY(0px)' },
            config: { tension: 120, friction: 14 },
        }),
        [game?.currentCard?.card]
    );

    useEffect(() => {
        api.start({
            from: { transform: 'translateY(-200px)' },
            to: { transform: 'translateY(0px)' },
            config: { tension: 120, friction: 14 },
            onRest: () => {
                oldCard.current = game.currentCard.card;
            },
        });
    }, [api, game.currentCard.card]);

    return (
        <div className={'relative'}>
            <animated.div style={props} className={'relative z-50'}>
                <UnoCard card={game?.currentCard?.card || 'Wuno'} />
            </animated.div>
            {oldCard.current && (
                <div className={'absolute top-0 left-0'}>
                    <UnoCard card={oldCard.current || 'Wuno'} />
                </div>
            )}
        </div>
    );
}
