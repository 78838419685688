import {
    Button,
    Card,
    CardActions,
    CardContent,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { translateColor } from '../utilities';

function ChanceCard({ card, number, hover, onHover, noPosition, show }) {
    return (
        <Card
            style={{
                top: noPosition
                    ? undefined
                    : 10 + number * 85 + (hover !== null && hover < number ? 260 : 0) + 'px',
                right: noPosition ? undefined : '15px',
                position: noPosition ? undefined : 'absolute',
                zIndex: hover === number ? 10 : 5,
                filter:
                    hover === number
                        ? 'brightness(100%)'
                        : hover !== null && hover !== number
                        ? 'brightness(50%)'
                        : 'none',
                boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.65)',
                transition: 'ease .2s',
                minHeight: '340px',
            }}
            onMouseEnter={() => {
                onHover && onHover(number);
            }}
            onMouseLeave={() => onHover && onHover(null)}
        >
            <CardContent>
                <Typography variant="h5" className={'mb-0 text-center'}>
                    Chancekort
                </Typography>
                {/*<Typography*/}
                {/*    sx={{*/}
                {/*        mb: 1.5,*/}
                {/*        color: (theme) => theme.palette?.[card.color.toLowerCase()]?.main,*/}
                {/*        fontSize: 13,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {translateColor(card.color)} grund*/}
                {/*</Typography>*/}
                <Typography
                    className={'mt-10 text-center'}
                    sx={{
                        mb: 1.5,
                        fontSize: 13,
                    }}
                >
                    {card.desc}
                </Typography>
            </CardContent>
            {card.type === 'unjail' && (
                <CardActions className={'justify-start'}>
                    <Button size="small" color={'success'} disabled>
                        Sælg/giv til anden
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}

export default ChanceCard;
