import {
    Avatar,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Icon,
    Button,
    Tooltip,
    Alert,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { selectSocketLoaded, socketListener } from '../store/socketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import { useNavigate } from 'react-router-dom';
import {
    addLog,
    selectCurrentPlayer,
    selectGame,
    selectGrounds,
    selectLogs,
    selectOfflinePlayers,
    selectPlayers,
    selectSellOffers,
    setCurrentPlayer,
    setCurrentThrow,
    setGame,
    setGrounds,
    setLogs,
    setPlayers,
    setQuestionDialog,
    setSelectedSellOffer,
} from './gameSlice';
import clsx from 'clsx';
import Box from '../ui-components/Box';
import CustomHR from '../ui-components/CustomHR';
import _ from 'lodash';
import apiService from '../services/apiService';
import LogEntry from './LogEntry';
import PlayerMoney from './PlayerMoney';
import DisconnectAlert from '../ui-components/DisconnectAlert';
import SellGroundsToOther from './SellGroundsToOther';

export default function Players() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socketLoaded = useSelector(selectSocketLoaded);
    const players = useSelector(selectPlayers);
    const offlinePlayers = useSelector(selectOfflinePlayers);
    const game = useSelector(selectGame);
    const grounds = useSelector(selectGrounds);
    const user = useSelector(selectUser);
    const logs = useSelector(selectLogs);
    const currentPlayer = useSelector(selectCurrentPlayer);
    const sellOffers = useSelector(selectSellOffers);
    const [sellToOtherDialog, setSellToOtherDialog] = React.useState(false);

    const getBackground = useCallback(
        (player) => {
            if (currentPlayer?.color === player.color) {
                if (sellOffers.some((sellOffer) => sellOffer.from_user_id === player.user_id)) {
                    return 'linear-gradient(90deg, rgba(69,248,130,0.6250875350140056) 0%, rgba(0,0,0,0) 50%, #ffa726 100%)';
                }
                return 'linear-gradient(90deg, rgba(69,248,130,0.6250875350140056) 0%, rgba(0,0,0,0) 58%, rgba(0,0,0,0) 100%)';
            }
            if (sellOffers.some((sellOffer) => sellOffer.from_user_id === player.user_id)) {
                return 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 42%, #ffa726 100%)';
            }
            return '#0d1116';
        },
        [currentPlayer, sellOffers]
    );

    const allPlayers = useMemo(() => {
        const _allPlayers = [];
        if (players) {
            _allPlayers.push(
                ...players.map((player) => ({
                    ...player,
                    type: 'online',
                    background: getBackground(player),
                }))
            );
        }
        if (offlinePlayers) {
            _allPlayers.push(
                ...offlinePlayers.map((player) => ({
                    ...player,
                    type: player.money <= 0 ? 'lost' : 'offline',
                    background: getBackground(player),
                }))
            );
        }
        _allPlayers.sort((a, b) => a.order_id - b.order_id);

        return _allPlayers;
    }, [players, offlinePlayers, getBackground]);

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    useEffect(() => {
        if (game?.state === 'LOBBY') {
            navigate('/lobby/' + game.id);
        } else if (game?.state === 'ENDED') {
            navigate('/end/' + game.id);
        }
    }, [game, navigate]);

    useEffect(() => {
        if (!game?.id) {
            return;
        }
        apiService
            .request('/game/matador/game/' + game.id + '/logs')
            .then((resp) => {
                return resp.logs;
            })
            .then((_logs) => {
                dispatch(setLogs(_logs));
            });
    }, [dispatch, game]);

    useEffect(() => {
        if (socketLoaded) {
            const logUnsubListener = dispatch(
                socketListener('matador', 'log', (response) => {
                    if (response.type === 'THROW') {
                        setTimeout(() => {
                            dispatch(addLog(response));
                        }, 1500);
                    } else {
                        dispatch(addLog(response));
                    }
                })
            );
            return () => {
                logUnsubListener();
            };
        }
        return () => {};
    }, [dispatch, socketLoaded]);

    const getsRemoved = useMemo(() => {
        return allPlayers.filter((player) => player.type === 'offline');
    }, [allPlayers]);

    return (
        <div
            className={'flex flex-col items-start'}
            style={{
                position: 'absolute',
                // overflowY: 'auto',
                // overflowX: 'hidden',
                left: 0,
                top: 0,
                height: '100%',
                width: '275px',
                marginRight: '-8px',
            }}
            // style={{ position: 'fixed', top: '20px', left: 0, zIndex: 999 }}
        >
            {getsRemoved.length > 0 && (
                <div className={'fixed top-0 w-full'}>
                    <DisconnectAlert players={getsRemoved} />
                </div>
            )}
            <div className={'w-full h-full rounded-none'} style={{ backgroundColor: '#0d1116' }}>
                <div className={'flex justify-between py-10 px-6'}>
                    <Typography className={'text-center text-white font-bold'} variant={'h5'}>
                        Spillere
                    </Typography>
                    <Button onClick={() => setSellToOtherDialog(true)}>Opret bud</Button>
                    {sellToOtherDialog && (
                        <SellGroundsToOther
                            defaultSelected={[]}
                            onClose={() => setSellToOtherDialog(false)}
                        />
                    )}
                </div>
                {allPlayers?.map((player, index) => (
                    <div
                        key={'player-' + player.user_id}
                        className={clsx(
                            'rounded-l-none relative',
                            player.type === 'offline' && 'opacity-50',
                            player.type === 'lost' && 'opacity-75'
                        )}
                        style={{
                            background: player.background,
                            borderBottom: '1px solid #232a30',
                            borderTop: index !== 0 ? 'none' : '1px solid #232a30',
                        }}
                    >
                        <div className={'absolute w-92'} style={{ left: '275px', zIndex: 10 }}>
                            {sellOffers
                                .filter((sellOffer) => sellOffer.from_user_id === player.user_id)
                                .map((sellOffer, index) => (
                                    <Button
                                        size={'small'}
                                        color={'warning'}
                                        variant={'contained'}
                                        className={'rounded-l-none py-1'}
                                        style={{
                                            marginBottom: '0.16rem',
                                        }}
                                        onClick={() => dispatch(setSelectedSellOffer(sellOffer))}
                                    >
                                        Se bud #{index + 1}{' '}
                                    </Button>
                                ))}
                            {playerSelf.ownPlayerOffers
                                .filter((offer) => offer.to_user_id === player.user_id)
                                .map((offer) => (
                                    <Tooltip
                                        title={
                                            'Afventer et svar på dit bud givet til denne spiller. Klik for at se eller annuller det'
                                        }
                                    >
                                        <Button
                                            size={'small'}
                                            color={'warning'}
                                            variant={'contained'}
                                            className={'rounded-l-none py-1'}
                                            style={{
                                                marginBottom: '0.16rem',
                                            }}
                                            onClick={() =>
                                                dispatch(
                                                    setSelectedSellOffer({ ...offer, own: true })
                                                )
                                            }
                                        >
                                            Afventer svar
                                        </Button>
                                    </Tooltip>
                                ))}
                        </div>
                        <div className={'flex items-center justify-between px-8 py-4 text-white'}>
                            <div
                                className={
                                    'flex flex-col items-center justify-center min-w-52 relative'
                                }
                            >
                                <Avatar
                                    src={
                                        player.avatar
                                            ? `https://cdn.discordapp.com/avatars/${player.user_id}/${player.avatar}.png`
                                            : `https://cdn.discordapp.com/embed/avatars/${
                                                  player.discriminator % 5
                                              }.png`
                                    }
                                    sx={{
                                        border: (theme) =>
                                            '3px solid ' + theme.palette[player.color].main,
                                    }}
                                    className={clsx(
                                        'w-24 h-24',
                                        player.money < 0 && 'opacity-20',
                                        player.jailed > 0 && 'opacity-75'
                                    )}
                                />
                                {player.jailed > 0 && (
                                    <Icon
                                        sx={{
                                            color: (theme) => theme.palette[player.color].main,
                                        }}
                                        className={'absolute text-5xl'}
                                        style={{ top: '-2px' }}
                                    >
                                        block
                                    </Icon>
                                )}
                                <Typography className={'font-bold text-sm mt-4'}>
                                    {player.username}
                                </Typography>
                            </div>
                            <div className={'flex flex-col items-center pr-2 min-w-84 relative'}>
                                {player.type === 'lost' && (
                                    <>
                                        <Typography>KONKURS</Typography>
                                    </>
                                )}
                                {player.type === 'offline' && (
                                    <>
                                        <Typography>OFFLINE</Typography>
                                    </>
                                )}
                                {player.type === 'online' && (
                                    <>
                                        <PlayerMoney player={player} />
                                        {player.jailed > 0 && (
                                            <Typography
                                                className={
                                                    'text-xs px-6 py-2 rounded-md text-center'
                                                }
                                                sx={{
                                                    bgcolor: (theme) => theme.palette.primary.main,
                                                    color: (theme) =>
                                                        theme.palette.getContrastText(
                                                            theme.palette.primary.main
                                                        ),
                                                }}
                                            >
                                                Fængslet ({player.jailed} runder)
                                            </Typography>
                                        )}
                                        {!player.jailed && (
                                            <Typography
                                                className={
                                                    'text-xs px-6 py-2 rounded-md text-center'
                                                }
                                                sx={{
                                                    bgcolor: (theme) =>
                                                        theme.palette[
                                                            grounds
                                                                ?.find(
                                                                    (ground) =>
                                                                        ground.number ===
                                                                        player.ground
                                                                )
                                                                ?.color?.toLowerCase() || 'primary'
                                                        ]?.main || theme.palette.primary.main,
                                                    color: (theme) =>
                                                        theme.palette.getContrastText(
                                                            theme.palette[
                                                                grounds
                                                                    ?.find(
                                                                        (ground) =>
                                                                            ground.number ===
                                                                            player.ground
                                                                    )
                                                                    ?.color?.toLowerCase() ||
                                                                    'primary'
                                                            ]?.main || theme.palette.primary.main
                                                        ),
                                                }}
                                            >
                                                {
                                                    grounds?.find(
                                                        (ground) => ground.number === player.ground
                                                    )?.name
                                                }
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <Typography className={'text-center pt-10 text-white font-bold'} variant={'h5'}>
                    Aktiviteter
                </Typography>
                <div
                    style={{
                        maxHeight: 'calc(100vh - ' + (48 + 84.5 * players?.length + 64) + 'px)',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                    }}
                >
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'transparent',
                            maxHeight: '25%',
                        }}
                        dense
                    >
                        {logs.map((log) => (
                            <React.Fragment key={'log-' + log.id}>
                                <LogEntry log={log} />
                            </React.Fragment>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );
}
