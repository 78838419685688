import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UnoCard from './UnoCard';
import { selectCurrentPlayer, selectGame, selectPlayer } from './gameSlice';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { selectSocketByNamespace } from '../store/socketSlice';
import ColorSelectDialog from './ColorSelectDialog';
import UsePlusCardDialog from './UsePlusCardDialog';
import _ from 'lodash';
import { Typography } from '@mui/material';
import { getRandomLetter } from '../utilities';
import moment from 'moment-timezone';

function UnoCards() {
    const game = useSelector(selectGame);
    const player = useSelector(selectPlayer);
    const currentPlayer = useSelector(selectCurrentPlayer);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'uno'));
    const [colorSelectDialogOpen, setColorSelectDialogOpen] = useState(null);
    const [jumpInTimeout, setJumpInTimeout] = useState(false);

    const cards = useMemo(() => {
        const _cards = _.cloneDeep(player?.cards || []);
        _cards.sort((a, b) => {
            if (a.type === 'WILD' || a.type === 'WILD_PLUS4') {
                return 1;
            }
            if (b.type === 'WILD' || b.type === 'WILD_PLUS4') {
                return -1;
            }
            if (a.color === b.color) {
                return a.number - b.number;
            }
            return b.color.localeCompare(a.color);
        });
        return _cards;
    }, [player]);

    const spacing = useMemo(() => {
        if (cards.length > 100) {
            return 150;
        }
        if (cards.length > 40) {
            return 120;
        }
        if (cards.length > 30) {
            return 110;
        }
        if (cards.length > 20) {
            return 100;
        }
        if (cards.length > 10) {
            return 75;
        }
        return 50;
    }, [cards.length]);

    function onColorSelect(color) {
        if (color) {
            socket.emit('card-play', { card: colorSelectDialogOpen, color });
        }
        setColorSelectDialogOpen(null);
    }

    function onPlusCardSelect(card) {
        if (card) {
            if (card.type === 'WILD_PLUS4') {
                setColorSelectDialogOpen(card);
                return;
            }
            socket.emit('card-play', { card });
        } else {
            socket.emit('card-play', { card: 'new' });
        }
    }

    const lastPlayed = useMemo(() => {
        if (!game?.lastPlay) {
            return null;
        }
        return moment().diff(
            moment.tz(game?.lastPlay, 'Etc/GMT').add(1, 'hour').tz('Europe/Copenhagen'),
            'seconds'
        );
    }, [game]);

    useEffect(() => {
        if (!game?.settings?.allowJumpInWithTimeout) {
            setJumpInTimeout(false);
            return;
        }
        const timeBack = 3 - lastPlayed;
        if (timeBack <= 0) {
            setJumpInTimeout(true);
            return;
        }
        setJumpInTimeout(false);
        const timeout = setTimeout(() => {
            setJumpInTimeout(true);
        }, timeBack * 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [game, lastPlayed]);

    const canJumpIn = useMemo(() => {
        if (jumpInTimeout) {
            return null;
        }
        if (!game?.settings?.allowJumpIn && !game?.settings?.allowJumpInWithTimeout) {
            return null;
        }
        if (game?.currentCard?.type === 'WILD' || game?.currentCard?.type === 'WILD_PLUS4') {
            return game?.currentCard?.card.substring(0, game?.currentCard?.card.length - 1);
        }
        return game?.currentCard?.card;
    }, [game, jumpInTimeout]);

    const handleCardClick = useCallback(
        (card, force = false) => {
            if (!force && player?.user_id !== currentPlayer?.user_id && canJumpIn) {
                return;
            }
            if (card.type === 'WILD') {
                setColorSelectDialogOpen(card);
                return;
            } else if (card.type === 'WILD_PLUS4') {
                setColorSelectDialogOpen(card);
                return;
            }
            socket.emit('card-play', { card });
        },
        [canJumpIn, currentPlayer, player, socket]
    );

    const jumpInKey = useMemo(() => {
        if (!canJumpIn) {
            return null;
        }
        return getRandomLetter();
    }, [canJumpIn]);

    useEffect(() => {
        if (!canJumpIn) {
            return;
        }

        function handleKeyDown(e) {
            if (e.key.toLowerCase() === jumpInKey.toLowerCase()) {
                const card = cards.find((card) => card.card === canJumpIn);
                handleCardClick(card, true);
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [canJumpIn, cards, handleCardClick, jumpInKey, socket]);

    return (
        <div
            className={clsx('flex justify-center')}
            style={{
                marginLeft: spacing + 'px',
            }}
        >
            {cards.map((card, index) => (
                <div
                    key={card.card + '-' + index}
                    style={{
                        // top: '0px',
                        // right: 10 + index * spacing + 'px',
                        // position: 'absolute',
                        marginLeft: '-' + spacing + 'px',
                        zIndex: 5,
                    }}
                    className={clsx(
                        player?.user_id === currentPlayer?.user_id && 'card-hover cursor-pointer',
                        player?.user_id !== currentPlayer?.user_id &&
                            canJumpIn !== card.card &&
                            'opacity-40 noshadow',
                        player?.user_id !== currentPlayer?.user_id &&
                            canJumpIn &&
                            canJumpIn === card.card &&
                            'opacity-100 highlight z-50 cursor-pointer relative'
                    )}
                    onClick={() => handleCardClick(card)}
                >
                    <UnoCard card={card.card} />
                    {canJumpIn === card.card && (
                        <div
                            style={{
                                top: '-120px',
                                position: 'absolute',
                            }}
                            className={'w-full text-center text-white'}
                        >
                            <div className={'key-button'}>{jumpInKey}</div>
                            <Typography className={'mt-4 text-sm'}>Jump In</Typography>
                        </div>
                    )}
                </div>
            ))}
            {!!colorSelectDialogOpen && <ColorSelectDialog onClose={onColorSelect} />}
            {player?.user_id === currentPlayer?.user_id && !game?.turnState && game?.cards > 0 && (
                <UsePlusCardDialog onClose={onPlusCardSelect} />
            )}
        </div>
    );
}

export default UnoCards;
