class ApiService {
    constructor(props) {
        this.uri = `${document.location.origin}/api`;
        if (document.location.origin.includes('localhost')) {
            this.uri = 'http://localhost:3917/api';
        }
    }

    init() {
        this.handleAuthentication();
    }

    getUri() {
        return this.uri;
    }

    async request(uri, method, body) {
        const options = {
            method,
            body,
            credentials: 'include',
        };
        if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
            options.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
            options.body = JSON.stringify(body);
        }
        if (!options.headers) {
            options.headers = {};
        }
        options.headers['authorization'] = localStorage.getItem('jwt_access_token');
        const resp = await fetch(this.uri + uri, options);
        const json = await resp.json();
        if (json.success !== undefined && json.success === false) {
            console.error(uri, json);
            const error = new Error(json.error || 'Unknown error');
            error.message = json.message;
            throw error;
        }
        console.log(uri, json);
        return json;
    }

    loginAnonymously = async () => {
        const response = await this.request('/auth/login-anonymously', 'POST');
        window.localStorage.setItem('jwt_access_token', response.token);

        return !!response.success;
    };

    login = async (code) => {
        if (!code) {
            return false;
        }
        const response = await this.request(
            '/auth/login?redirect=' + encodeURI(window.location.origin + '/login'),
            'POST',
            {
                code,
            }
        );
        window.localStorage.setItem('jwt_access_token', response.token);

        return !!response.success;
    };

    logout = async () => {
        window.localStorage.removeItem('jwt_access_token');

        return true;
    };

    verify = async () => {
        const access_token = window.localStorage.getItem('jwt_access_token');
        if (!access_token) {
            return false;
        }
        const response = await this.request('/auth/verify', 'POST', {
            token: access_token,
        });

        return response?.decoded;
    };
}

const instance = new ApiService();

export default instance;
