import {
    Alert,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import _ from 'lodash';

const numberFormatter = new Intl.NumberFormat('da-DK');

const settingsConfig = {
    username: {
        icon: ['fas', 'fa-user'],
        title: 'Username',
        description: 'The username you will be known as in the lobby',
        type: 'text',
        defaultValuePath: 'discordUser.username',
    },
};

export default function LobbyOwnSettings({ onClose, defaultValues }) {
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const _settings = {};
        Object.entries(settingsConfig).forEach(([id, settingData]) => {
            _settings[id] =
                _.get(defaultValues, settingData.defaultValuePath) ?? settingData.default ?? false;
        });
        setSettings(_settings);
    }, [defaultValues]);

    const disabledSettings = useMemo(() => {
        return Object.entries(settingsConfig).reduce((acc, [id, settingData]) => {
            if (settingData.disabledIf) {
                const disabled = settingData.disabledIf.some(
                    (disabledId) => settings?.[disabledId] === true
                );
                if (disabled) {
                    acc.push(id);
                }
            }
            return acc;
        }, []);
    }, [settings]);

    const disabled = useMemo(() => {
        return false;
    }, []);

    async function handleClose(save) {
        if (save) {
            setLoading(true);
            await onClose(settings);
            setLoading(false);
        } else {
            onClose();
        }
    }

    function toggleSetting(id) {
        setSettings((oldState) => {
            return {
                ...oldState,
                [id]: !oldState[id],
            };
        });
    }
    function handleSettingChange(e, id) {
        const val = e.currentTarget.value;
        setSettings((oldState) => {
            return {
                ...oldState,
                [id]: val,
            };
        });
    }

    async function handleKeyUp(e) {
        if (e.key === 'Enter') {
            await handleClose(true);
        }
    }

    return (
        <Dialog open onClose={() => handleClose(false)} maxWidth={'md'} fullWidth>
            <DialogTitle className={'text-center'}>User Settings</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {Object.entries(settingsConfig).map(([id, settingData]) => (
                        <Grid item xs={6} key={'settings-' + id}>
                            <Card>
                                <CardContent className={'pb-10 flex items-center h-full'}>
                                    {settingData.type === 'text' && (
                                        <div className={'flex items-center w-full'}>
                                            <div className={'w-32 flex justify-center mr-14 ml-4'}>
                                                <FontAwesomeIcon
                                                    icon={settingData.icon}
                                                    size={'3x'}
                                                />
                                            </div>
                                            <div className={'w-full'}>
                                                <TextField
                                                    fullWidth
                                                    className={'w-full'}
                                                    size={'small'}
                                                    label={settingData.title}
                                                    helperText={settingData.description}
                                                    value={settings?.[id] || ''}
                                                    onChange={(e) => handleSettingChange(e, id)}
                                                    onKeyUp={handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {settingData.type === 'checkbox' && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!settings?.[id]}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                />
                                            }
                                            onChange={() => toggleSetting(id)}
                                            disabled={disabledSettings.includes(id)}
                                            label={
                                                <div className={'flex items-center'}>
                                                    <div
                                                        className={
                                                            'w-32 flex justify-center mr-10 ml-4'
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={settingData.icon}
                                                            size={'3x'}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography variant={'h6'}>
                                                            {settingData.title}
                                                        </Typography>
                                                        <Typography variant={'body2'}>
                                                            {settingData.description}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                {/*{disabled === 'money' && (*/}
                {/*    <Alert variant={'filled'} severity={'error'} className={'mr-10 py-0'}>*/}
                {/*        Du har ikke råd til at købe dette.*/}
                {/*    </Alert>*/}
                {/*)}*/}
                <LoadingButton
                    onClick={() => handleClose(true)}
                    variant={'contained'}
                    disabled={!!disabled}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
