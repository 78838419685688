import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
} from '@mui/material';
import GroundCard from './GroundCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import { selectSocketByNamespace, selectSocketLoaded, socketListener } from '../store/socketSlice';
import {
    selectCurrentPlayer,
    selectCurrentThrow,
    selectGrounds,
    selectGroundView,
    selectPlayers,
    selectQuestionDialog,
    setGroundView,
    setQuestionDialog,
    setSellFor,
} from './gameSlice';
import Dice from './Dice';
import ChanceCard from './ChanceCard';
import clsx from 'clsx';
import CustomHR from '../ui-components/CustomHR';
import _ from 'lodash';

function QuestionDialog() {
    const dispatch = useDispatch();
    const players = useSelector(selectPlayers);
    const grounds = useSelector(selectGrounds);
    const groundView = useSelector(selectGroundView);
    const questionDialogRaw = useSelector(selectQuestionDialog);
    const user = useSelector(selectUser);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'matador'));

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    const questionDialog = useMemo(() => {
        if (questionDialogRaw) {
            return questionDialogRaw;
        }
        if (groundView) {
            const ground = _.cloneDeep(
                grounds.find((ground) => ground.groundMeshId === groundView) || null
            );
            if (!ground) {
                dispatch(setGroundView(null));
                return null;
            }
            let title;
            const messages = [];
            if (['GROUND', 'SHIP', 'SQUASH', 'COCACOLA'].includes(ground.special)) {
                const groundOwner = players.find((player) =>
                    player.cards.some((card) => card.number === ground.number)
                );
                if (groundOwner) {
                    const ownerCard = groundOwner?.cards.find(
                        (card) => card.number === ground.number
                    );
                    messages.push('Denne grund er ejet af', groundOwner?.username);
                    ground.hotels = ownerCard.hotels;
                    ground.houses = ownerCard.houses;
                } else {
                    messages.push('Denne grund er ikke ejet af nogen');
                }
                title = 'Grund: ' + ground.name;
            } else if (ground.special === 'START') {
                messages.push('Dette er start. Hver gang De passerer start modtager De 4.000,-');
                title = 'Start';
            } else if (ground.special === 'TAX') {
                messages.push('Betal indkomstskat:', '10% af alle dine aktiver', 'eller 4.000,-');
                title = 'Indkomstskat';
            } else if (ground.special === 'QUESTION') {
                messages.push(
                    'Prøv lykken; De modtager et chancekort, og skal følge instruktionerne på kortet'
                );
                title = 'Prøv lykken';
            } else if (ground.special === 'JAILVISIT') {
                messages.push('De er på besøg i fængslet, og skal foretage Dem yderligere');
                title = 'På besøg';
            } else if (ground.special === 'PARK') {
                messages.push(
                    'De modtager et beløb svarende til alles udgifter i enten SKAT eller via chancekort, samlet sammen igennem alle runder siden sidste spiller landte på denne'
                );
                title = 'Parkering';
            } else if (ground.special === 'JAIL') {
                messages.push(
                    'De fængsles, og skal gå direkte til feltet "På besøg". Næste runde kan De betale 1.000,- for at komme ud af fængslet, eller slå to ens'
                );
                title = 'Fængsel';
            } else if (ground.special === 'TAX2') {
                messages.push('Ekstraordinær statsskat: Betal 2.000,-');
                title = 'Statsskat';
            }
            return {
                title,
                messages,
                ground: ['GROUND', 'SHIP', 'SQUASH', 'COCACOLA'].includes(ground.special)
                    ? ground
                    : null,
                buttons: [
                    {
                        result: true,
                        text: 'Luk',
                        variant: 'contained',
                        color: 'success',
                    },
                ],
            };
        }
        return null;
    }, [dispatch, groundView, grounds, players, questionDialogRaw]);

    function handleButtonClick(result) {
        if (questionDialogRaw) {
            socket.emit('question-dialog-result', {
                actionId: questionDialog.actionId,
                result,
            });
            dispatch(setQuestionDialog(null));
        } else {
            dispatch(setGroundView(null));
        }
    }

    const forUser = React.useMemo(() => {
        if (!questionDialog?.player || playerSelf?.user_id === questionDialog?.player) {
            return null;
        }
        return players.find((player) => player.user_id === questionDialog?.player);
    }, [questionDialog?.player, playerSelf?.user_id, players]);

    return (
        <Dialog
            sx={{
                '.MuiDialog-paper': {
                    bgcolor: '#1B242E',
                    color: (theme) => theme.palette.getContrastText('#1B242E'),
                    backgroundImage: 'unset',
                },
            }}
            onClose={() => dispatch(setGroundView(null))}
            open={!!questionDialog}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle
                className={'text-center'}
                style={{
                    backgroundColor: '#121a23',
                }}
            >
                {questionDialog?.title} {forUser && '(' + forUser?.username + ')'}
            </DialogTitle>
            <CustomHR />
            <DialogContent>
                <div className={'flex justify-between'}>
                    {questionDialog?.ground && (
                        <div style={{ width: '255px' }}>
                            <GroundCard
                                card={questionDialog?.ground}
                                number={0}
                                hover={null}
                                noPosition
                                show
                            />
                        </div>
                    )}
                    {questionDialog?.card && (
                        <div style={{ width: '255px' }}>
                            <ChanceCard
                                card={questionDialog?.card}
                                number={0}
                                hover={null}
                                noPosition
                                show
                            />
                        </div>
                    )}
                    <div
                        style={
                            questionDialog?.card || questionDialog?.ground
                                ? { width: '270px' }
                                : { width: '100%' }
                        }
                        className={'relative'}
                    >
                        {questionDialog?.messages.map((message) => (
                            <Typography className={'mt-2'}>{message}</Typography>
                        ))}
                        <div
                            className={clsx(
                                'w-full',
                                (questionDialog?.card || questionDialog?.ground) &&
                                    'absolute bottom-0'
                            )}
                        >
                            {questionDialog?.buttons?.map((button) => (
                                <Button
                                    className={'w-full mt-6'}
                                    variant={button.variant}
                                    color={button.color}
                                    onClick={() => handleButtonClick(button.result)}
                                    disabled={!!button.disabled}
                                >
                                    {button.text}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default QuestionDialog;
