import { Alert } from '@mui/material';
import React from 'react';

export default function DisconnectAlert({ players }) {
    const [seconds, setSeconds] = React.useState(60);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setSeconds((oldState) => (oldState > 0 ? oldState - 1 : oldState));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Alert className={'w-full flex justify-center'} severity={'error'}>
            {players.map((player) => player.username).join(',')} er gået offline. De vil blive
            fjernet fra spillet om {seconds} sekunder.
        </Alert>
    );
}
