import {
    Button,
    Card,
    CardActions,
    CardContent,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { translateColor } from '../utilities';
import React from 'react';
import clsx from 'clsx';
import UpgradeGrounds from './UpgradeGrounds';
import SellGroundsToOther from './SellGroundsToOther';

function GroundCard({ card, number, hover, onClick, noPosition, show, pledged, onPledged, money }) {
    const [upgradeDialog, setUpgradeDialog] = React.useState(false);
    const [sellToOtherDialog, setSellToOtherDialog] = React.useState(false);
    const isSpecialDrinkGround = card.special === 'SQUASH' || card.special === 'COCACOLA';
    const isSpecialShipGround = card.special === 'SHIP';
    const isSpecialUnjailCard = card.special === 'UNJAIL';

    return (
        <Card
            style={{
                top: noPosition
                    ? undefined
                    : 10 + number * 85 + (hover !== null && hover < number ? 260 : 0) + 'px',
                right: noPosition ? undefined : '15px',
                position: noPosition ? undefined : 'absolute',
                zIndex: hover === number ? 10 : 5,
                filter:
                    hover === number
                        ? 'brightness(100%)'
                        : hover !== null && hover !== number
                        ? 'brightness(50%)'
                        : 'none',
                boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.65)',
                transition: 'ease .2s',
                width: '240px',
                cursor: onClick ? 'pointer' : 'default',
            }}
            onClick={() => {
                if (!onClick) {
                    return;
                }
                if (hover === number) {
                    onClick(null);
                } else {
                    onClick(number);
                }
            }}
        >
            <CardContent className={'relative'}>
                {pledged && noPosition && (
                    <>
                        <div
                            className={
                                'absolute bg-red-500 opacity-80 w-lg py-6 font-bold text-center'
                            }
                            style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%) rotate(45deg)',
                            }}
                        >
                            <Typography variant="h5" className={'inline-block rotate-45'}>
                                PANTSAT
                            </Typography>
                        </div>
                    </>
                )}
                {pledged && !noPosition && (
                    <>
                        <div
                            className={
                                'absolute bg-red-500 opacity-60 w-full py-6 font-bold text-center'
                            }
                            style={{
                                top: '5%',
                                left: '0',
                            }}
                        >
                            <Typography variant="h5" className={'inline-block'}>
                                PANTSAT
                            </Typography>
                        </div>
                    </>
                )}
                <div className={'flex justify-between'}>
                    <Typography variant="h5" className={'mb-0 text-white'}>
                        {card.name}
                    </Typography>
                    {!isSpecialDrinkGround && !isSpecialShipGround && !isSpecialUnjailCard && (
                        <>
                            {upgradeDialog && (
                                <UpgradeGrounds
                                    card={card}
                                    onClose={() => setUpgradeDialog(false)}
                                />
                            )}
                            <Tooltip
                                title={
                                    card.upgradeable
                                        ? 'Op-/nedgradér for ' +
                                          (card.houses >= 4
                                              ? card?.buyHousePrice[1]
                                              : card.buyHousePrice[0]) +
                                          ',-'
                                        : 'Kræver alle grunde i samme farve'
                                }
                            >
                                <span>
                                    <IconButton
                                        size={'small'}
                                        disabled={!!show || !card.upgradeable || pledged}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setUpgradeDialog(true);
                                        }}
                                    >
                                        <Icon>house</Icon>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    )}
                </div>
                {!isSpecialShipGround && !isSpecialDrinkGround && !isSpecialUnjailCard && (
                    <Typography
                        sx={{
                            mb: 1.5,
                            color: (theme) => theme.palette?.[card.color.toLowerCase()]?.main,
                            fontSize: 13,
                        }}
                    >
                        {translateColor(card.color)} grund
                    </Typography>
                )}
                {isSpecialDrinkGround && (
                    <Typography
                        sx={{
                            mb: 1.5,
                            color: (theme) => theme.palette?.primary?.main,
                            fontSize: 13,
                        }}
                    >
                        Virksomhed
                    </Typography>
                )}
                {isSpecialShipGround && (
                    <Typography
                        sx={{
                            mb: 1.5,
                            color: (theme) => theme.palette?.primary?.main,
                            fontSize: 13,
                        }}
                    >
                        Rederi
                    </Typography>
                )}
                {isSpecialUnjailCard && (
                    <Typography
                        sx={{
                            mb: 1.5,
                            color: (theme) => theme.palette?.primary?.main,
                            fontSize: 13,
                        }}
                    >
                        Løsladelseskort
                    </Typography>
                )}
                {isSpecialDrinkGround && (
                    <>
                        <Typography
                            className={clsx(card.amountOfCards !== 1 && 'opacity-50')}
                            sx={{
                                mb: 1.5,
                                fontSize: 13,
                            }}
                        >
                            Hvis 1 virksomhed ejes, betales 100 gange så meget som øjnene viser.
                        </Typography>
                        <Typography
                            className={clsx(card.amountOfCards !== 2 && 'opacity-50')}
                            sx={{
                                mb: 1.5,
                                fontSize: 13,
                            }}
                        >
                            Hvis både Coca-Cola og Squash ejes, betales 200 gange så meget, som
                            øjnene viser.
                        </Typography>
                    </>
                )}
                {isSpecialUnjailCard && (
                    <>
                        <Typography
                            sx={{
                                mb: 1.5,
                                fontSize: 13,
                            }}
                        >
                            I anledning af kongens fødselsdag benådes De herved for fængsel.
                        </Typography>
                        <Typography
                            sx={{
                                mb: 1.5,
                                fontSize: 13,
                            }}
                        >
                            Dette kort kan opbevares, indtil De får brug for det eller De kan sælge
                            det.
                        </Typography>
                    </>
                )}
                {isSpecialShipGround && (
                    <Table size={'small'}>
                        <TableBody>
                            <TableRow className={clsx(card.amountOfCards !== 1 && 'opacity-50')}>
                                <TableCell>Leje</TableCell>
                                <TableCell>{card.prices[0]},-</TableCell>
                            </TableRow>
                            <TableRow className={clsx(card.amountOfCards !== 2 && 'opacity-50')}>
                                <TableCell>Hvis 2 ejes</TableCell>
                                <TableCell>{card.prices[1]},-</TableCell>
                            </TableRow>
                            <TableRow className={clsx(card.amountOfCards !== 3 && 'opacity-50')}>
                                <TableCell>Hvis 3 ejes</TableCell>
                                <TableCell>{card.prices[2]},-</TableCell>
                            </TableRow>
                            <TableRow className={clsx(card.amountOfCards !== 4 && 'opacity-50')}>
                                <TableCell>Hvis 4 ejes</TableCell>
                                <TableCell>{card.prices[3]},-</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
                {!isSpecialDrinkGround && !isSpecialShipGround && !isSpecialUnjailCard && (
                    <Table size={'small'}>
                        <TableBody>
                            <TableRow
                                className={clsx(
                                    card.houses + card.hotels * 5 !== 0 && 'opacity-50'
                                )}
                            >
                                <TableCell>Leje af grund</TableCell>
                                <TableCell>{card.prices[0]},-</TableCell>
                            </TableRow>
                            <TableRow
                                className={clsx(
                                    card.houses + card.hotels * 5 !== 1 && 'opacity-50'
                                )}
                            >
                                <TableCell>1 hus</TableCell>
                                <TableCell>{card.prices[1]},-</TableCell>
                            </TableRow>
                            <TableRow
                                className={clsx(
                                    card.houses + card.hotels * 5 !== 2 && 'opacity-50'
                                )}
                            >
                                <TableCell>2 huse</TableCell>
                                <TableCell>{card.prices[2]},-</TableCell>
                            </TableRow>
                            <TableRow
                                className={clsx(
                                    card.houses + card.hotels * 5 !== 3 && 'opacity-50'
                                )}
                            >
                                <TableCell>3 huse</TableCell>
                                <TableCell>{card.prices[3]},-</TableCell>
                            </TableRow>
                            <TableRow
                                className={clsx(
                                    card.houses + card.hotels * 5 !== 4 && 'opacity-50'
                                )}
                            >
                                <TableCell>4 huse</TableCell>
                                <TableCell>{card.prices[4]},-</TableCell>
                            </TableRow>
                            <TableRow
                                className={clsx(
                                    card.houses + card.hotels * 5 !== 5 && 'opacity-50'
                                )}
                            >
                                <TableCell>Hotel</TableCell>
                                <TableCell>{card.prices[5]},-</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                )}
            </CardContent>
            <CardActions className={'justify-between'}>
                <Button
                    size="small"
                    color={'success'}
                    disabled={!!show || pledged}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSellToOtherDialog(true);
                    }}
                >
                    Sælg til anden
                </Button>
                {sellToOtherDialog && (
                    <SellGroundsToOther
                        defaultSelected={[card.number]}
                        onClose={() => setSellToOtherDialog(false)}
                    />
                )}
                {!isSpecialUnjailCard && (
                    <Tooltip
                        title={
                            pledged
                                ? money >= card.pant * 100
                                    ? 'Køb tilbage for ' + card.pant + ',-'
                                    : 'Du har ikke nok penge'
                                : card?.houses > 0 || card?.hotels > 0
                                ? 'Sælg alle huse og hoteller først'
                                : 'Pantsæt for ' + card.pant + ',-'
                        }
                    >
                        <span>
                            <Button
                                size="small"
                                color={pledged ? 'success' : 'error'}
                                disabled={
                                    !!show ||
                                    card?.houses > 0 ||
                                    card?.hotels > 0 ||
                                    (pledged && money < card.pant * 100)
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onPledged();
                                }}
                            >
                                {pledged ? 'Køb tilbage' : 'Pantsæt'}
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </CardActions>
        </Card>
    );
}

export default GroundCard;
