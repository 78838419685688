import { styled } from 'styled-components';
import { useTheme } from '@mui/material';

const CustomButton = styled.a`
    background: transparent;
    border: 0;
    display: inline-flex;
    text-transform: uppercase;
    padding: 15px 49px;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.8px;
    position: relative;
    overflow: hidden;
    justify-content: center;
    text-align: center;
    min-width: 195px;
    z-index: 1;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(/assets/shape1.svg);
        width: 30px;
        height: calc(100% + 6px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
    }

    &:after {
        left: auto;
        right: 0;
        transform: translateY(-50%) rotateY(180deg);
    }

    span:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 95%;
        background: ${(props) => props.color || '#45f882'};
        clip-path: polygon(
            28px 0,
            calc(90% + 2px) 0,
            100% 50%,
            calc(89% + 1px) 100%,
            28px 99%,
            0% 50%
        );
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transition: all 0.3s ease-out 0s;
        z-index: -1;
    }
    &:hover span:after {
        background: #fff;
    }
`;

export default function Button({ className, onClick, children, disabled, color }) {
    const theme = useTheme();

    return (
        <CustomButton
            className={className}
            onClick={!disabled ? onClick : undefined}
            color={color ? theme.palette[color].main : null}
        >
            <span className="uppercase">{children}</span>
        </CustomButton>
    );
}
