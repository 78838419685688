import { Avatar, Typography, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { selectSocketByNamespace, selectSocketLoaded, socketListener } from '../store/socketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import { useNavigate } from 'react-router-dom';
import {
    addLog,
    selectCurrentPlayer,
    selectGame,
    selectOfflinePlayers,
    selectPlayers,
    setLogs,
} from './gameSlice';
import clsx from 'clsx';
import apiService from '../services/apiService';
import DisconnectAlert from '../ui-components/DisconnectAlert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Players() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socketLoaded = useSelector(selectSocketLoaded);
    const players = useSelector(selectPlayers);
    const offlinePlayers = useSelector(selectOfflinePlayers);
    const game = useSelector(selectGame);
    const user = useSelector(selectUser);
    const currentPlayer = useSelector(selectCurrentPlayer);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'uno'));

    const getBackground = useCallback(
        (player) => {
            if (currentPlayer?.user_id === player.user_id) {
                // if (sellOffers.some((sellOffer) => sellOffer.from_user_id === player.user_id)) {
                //     return 'linear-gradient(90deg, rgba(69,248,130,0.6250875350140056) 0%, rgba(0,0,0,0) 50%, #ffa726 100%)';
                // }
                return 'linear-gradient(90deg, rgba(69,248,130,0.6250875350140056) 0%, rgba(0,0,0,0) 58%, rgba(0,0,0,0) 100%)';
            }
            return '#0d1116';
        },
        [currentPlayer]
    );

    const allPlayers = useMemo(() => {
        const _allPlayers = [];
        if (players) {
            _allPlayers.push(
                ...players.map((player) => ({
                    ...player,
                    type: 'online',
                    background: getBackground(player),
                }))
            );
        }
        if (offlinePlayers) {
            _allPlayers.push(
                ...offlinePlayers.map((player) => ({
                    ...player,
                    type: !!player.finished ? 'won' : 'offline',
                    background: getBackground(player),
                }))
            );
        }
        _allPlayers.sort((a, b) => a.order_id - b.order_id);

        return _allPlayers;
    }, [players, offlinePlayers, getBackground]);

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    useEffect(() => {
        if (game?.state === 'LOBBY') {
            navigate('/lobby/' + game.id);
        } else if (game?.state === 'ENDED') {
            navigate('/end/' + game.id);
        }
    }, [game, navigate]);

    const getsRemoved = useMemo(() => {
        return allPlayers.filter((player) => player.type === 'offline');
    }, [allPlayers]);

    function handleUnoMissed(player) {
        socket.emit('uno-missed', { player });
    }

    return (
        <div
            className={'flex flex-col items-start'}
            style={{
                height: '100%',
                width: '275px',
            }}
            // style={{ position: 'fixed', top: '20px', left: 0, zIndex: 999 }}
        >
            {getsRemoved.length > 0 && (
                <div className={'fixed top-0 w-full'}>
                    <DisconnectAlert players={getsRemoved} />
                </div>
            )}
            <div className={'w-full h-full rounded-none'} style={{ backgroundColor: '#0d1116' }}>
                <div className={'flex justify-between py-10 px-6'}>
                    <Typography className={'text-center text-white font-bold'} variant={'h5'}>
                        Players
                    </Typography>
                    {/*<Button onClick={() => setSellToOtherDialog(true)}>Opret bud</Button>*/}
                </div>
                {allPlayers?.map((player, index) => (
                    <div
                        key={'player-' + player.user_id}
                        className={clsx(
                            'rounded-l-none relative',
                            player.type === 'offline' && 'opacity-50',
                            player.type === 'lost' && 'opacity-75'
                        )}
                        style={{
                            background: player.background,
                            borderBottom: '1px solid #232a30',
                            borderTop: index !== 0 ? 'none' : '1px solid #232a30',
                        }}
                    >
                        {player.cards === 1 && !player.unoCalled && player.id !== playerSelf?.id && (
                            <div
                                className={'absolute w-92 flex items-center justify-center'}
                                style={{
                                    left: '275px',
                                    zIndex: 10,
                                    top: '0',
                                    bottom: '0',
                                    height: '81.563px',
                                }}
                            >
                                <Button
                                    size={'small'}
                                    color={'warning'}
                                    variant={'contained'}
                                    className={'rounded-l-none py-1'}
                                    onClick={() => handleUnoMissed(player)}
                                >
                                    FORGOT TO SAY UNO!
                                </Button>
                            </div>
                        )}
                        {index !== 0 && (
                            <ExpandMoreIcon
                                className={'absolute'}
                                style={{
                                    top: '-20px',
                                    left: '50%',
                                    transform:
                                        'translateX(-50%) ' +
                                        (game.orderDirection === 'asc' ? 'rotate(180deg)' : ''),
                                    fontSize: '40px',
                                }}
                                sx={{
                                    color: (theme) => theme.palette.primary.main,
                                }}
                            />
                        )}
                        {player.id === playerSelf?.id && (
                            <div
                                className={'absolute text-center'}
                                style={{
                                    transform: 'rotate(40deg)',
                                    right: '-1.3rem',
                                    top: '-0.4rem',
                                    width: '40px',
                                    borderBottom: '20px solid rgb(187, 247, 208)',
                                    borderLeft: '24px solid transparent',
                                    borderRight: '17px solid transparent',
                                }}
                            >
                                <Typography
                                    className={'text-green-800 relative text-xs font-bold'}
                                    style={{
                                        right: '.1rem',
                                        top: '1.2rem',
                                    }}
                                >
                                    YOU
                                </Typography>
                            </div>
                        )}
                        <div className={'flex items-center justify-between px-8 py-4 text-white'}>
                            <div
                                className={
                                    'flex flex-col items-center justify-center min-w-52 relative'
                                }
                            >
                                <Avatar
                                    src={
                                        player.avatar
                                            ? `https://cdn.discordapp.com/avatars/${player.user_id}/${player.avatar}.png`
                                            : `https://cdn.discordapp.com/embed/avatars/${
                                                  player.discriminator % 5
                                              }.png`
                                    }
                                    sx={{
                                        border: (theme) =>
                                            '2px solid ' + theme.palette.primary.main,
                                    }}
                                    className={clsx(
                                        'w-24 h-24',
                                        player.money < 0 && 'opacity-20',
                                        player.jailed > 0 && 'opacity-75'
                                    )}
                                />
                                <Typography className={'font-bold text-sm mt-4'}>
                                    {player.username}
                                </Typography>
                            </div>
                            <div className={'flex flex-col items-center pr-2 min-w-84 relative'}>
                                {player.type === 'won' && (
                                    <>
                                        <Typography>#{player.finished}</Typography>
                                    </>
                                )}
                                {player.type === 'offline' && (
                                    <>
                                        <Typography>OFFLINE</Typography>
                                    </>
                                )}
                                {player.type === 'online' && (
                                    <>
                                        <Typography
                                            className={'text-xs px-6 py-2 rounded-md text-center'}
                                            sx={{
                                                bgcolor: (theme) => theme.palette.primary.main,
                                                color: (theme) =>
                                                    theme.palette.getContrastText(
                                                        theme.palette.primary.main
                                                    ),
                                            }}
                                        >
                                            {player.cards} card{player.cards !== 1 && <>s</>}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                {/*<Typography className={'text-center pt-10 text-white font-bold'} variant={'h5'}>*/}
                {/*    Chat*/}
                {/*</Typography>*/}
                {/*<div*/}
                {/*    style={{*/}
                {/*        maxHeight: 'calc(100vh - ' + (48 + 84.5 * players?.length + 64) + 'px)',*/}
                {/*        overflow: 'hidden',*/}
                {/*        overflowY: 'scroll',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <List*/}
                {/*        sx={{*/}
                {/*            width: '100%',*/}
                {/*            bgcolor: 'transparent',*/}
                {/*            maxHeight: '25%',*/}
                {/*        }}*/}
                {/*        dense*/}
                {/*    >*/}
                {/*        {logs.map((log) => (*/}
                {/*            <React.Fragment key={'log-' + log.id}>*/}
                {/*                <LogEntry log={log} />*/}
                {/*            </React.Fragment>*/}
                {/*        ))}*/}
                {/*    </List>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
