import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import { selectSocketByNamespace } from '../store/socketSlice';
import { selectGame, selectPlayer, selectPlayers } from './gameSlice';
import CustomHR from '../ui-components/CustomHR';
import UnoCard from './UnoCard';
import clsx from 'clsx';

function UsePlusCardDialog({ onClose }) {
    const dispatch = useDispatch();
    const game = useSelector(selectGame);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'uno'));
    const [selectedCard, setSelectedCard] = useState(null);
    const player = useSelector(selectPlayer);

    function handleButtonClick(result) {
        if (result) {
            onClose(selectedCard);
        } else {
            onClose();
        }
    }

    const cards = useMemo(() => {
        return (
            player?.cards.filter(
                (c) =>
                    game.currentCard.type === c.type &&
                    (c.type === 'PLUS2' || c.type === 'WILD_PLUS4')
            ) || []
        );
    }, [game.currentCard.type, player?.cards]);

    useEffect(() => {
        if (cards.length === 1) {
            setSelectedCard(cards[0]);
        }
    }, [cards]);

    return (
        <Dialog
            sx={{
                '.MuiDialog-paper': {
                    bgcolor: '#1B242E',
                    color: (theme) => theme.palette.getContrastText('#1B242E'),
                    backgroundImage: 'unset',
                },
            }}
            onClose={() => handleButtonClick()}
            open
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle
                className={'text-center'}
                style={{
                    backgroundColor: '#121a23',
                }}
            >
                Play a card or draw cards
            </DialogTitle>
            <CustomHR />
            <DialogContent>
                {cards.map((card) => (
                    <div
                        className={clsx(
                            'cursor-pointer',
                            selectedCard && selectedCard.id !== card.id && 'opacity-40'
                        )}
                        onClick={() => setSelectedCard(card)}
                    >
                        <UnoCard card={card.card} />
                    </div>
                ))}
                <ButtonGroup className={'mt-10 w-full'}>
                    <Button
                        variant={'contained'}
                        color={'error'}
                        fullWidth
                        className={'w-1/2'}
                        onClick={() => handleButtonClick(false)}
                    >
                        Draw {game.cards} cards
                    </Button>
                    <Button
                        variant={'contained'}
                        fullWidth
                        className={'w-1/2'}
                        onClick={() => handleButtonClick(true)}
                        disabled={!selectedCard}
                    >
                        Play selected card
                    </Button>
                </ButtonGroup>
            </DialogContent>
        </Dialog>
    );
}

export default UsePlusCardDialog;
