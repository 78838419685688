import { styled } from 'styled-components';

const HR = styled.div`
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: ${(props) => (props.reverse ? 'unset' : '0')};
        right: ${(props) => (props.reverse ? '0' : 'unset')};
        bottom: ${(props) => (props.reverse ? 'unset' : '0')};
        top: ${(props) => (props.reverse ? '0' : 'unset')};
        transform: ${(props) => (props.reverse ? 'rotate(180deg)' : 'unset')};
        width: 50%;
        clip-path: polygon(0 0, 0 100%, 100% 100%);
        background-color: #45f882;
        height: 10px;
    }
    &:after {
        left: ${(props) => (props.reverse ? '0' : 'auto')};
        right: ${(props) => (props.reverse ? 'auto' : '0')};
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
    }
`;

export default function CustomHR({ ...props }) {
    return <HR {...props} />;
}
