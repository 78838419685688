import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getParameterByName } from '../utilities';
import apiService from '../services/apiService';
import Logo from '../ui-components/Logo';
import Button from '../ui-components/Button';
import { setupAuth } from '../store/authSlice';
import { useDispatch } from 'react-redux';

function Login() {
    const dispatch = useDispatch();
    const lobbyId = getParameterByName('id');
    const code = getParameterByName('code');
    const once = useRef(false);
    const navigate = useNavigate();
    if (lobbyId) {
        window.localStorage.setItem('join_lobby', lobbyId);
    }

    useEffect(() => {
        if (code && !once.current) {
            once.current = true;
            apiService.login(code).then((resp) => {
                dispatch(setupAuth());
                const lobby = window.localStorage.getItem('join_lobby');
                if (lobby) {
                    navigate('/lobby/' + lobby);
                    window.localStorage.removeItem('join_lobby');
                } else {
                    navigate('/lobby');
                }
            });
        }
    }, [code, dispatch, lobbyId, navigate]);

    return (
        <div className={'bg-gray-900 p-10 w-screen h-screen'}>
            <div className={'max-w-2xl m-auto mt-32 text-center'}>
                <div className={'flex items-center justify-center'}>
                    <Logo />
                </div>
                <Typography className={'text-lg text-white'}>
                    For at kunne spille, skal du logge ind med din Discord konto.
                </Typography>
                <div className={'flex justify-center mt-32'}>
                    <Button
                        color={'primary'}
                        onClick={() => {
                            if (window.location.origin.includes('localhost')) {
                                window.location.href =
                                    'https://discord.com/api/oauth2/authorize?client_id=990326559531475004&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&response_type=code&scope=identify';
                            } else {
                                window.location.href =
                                    'https://discord.com/api/oauth2/authorize?client_id=990326559531475004&redirect_uri=https%3A%2F%2Fmatadoren.dk%2Flogin&response_type=code&scope=identify';
                            }
                        }}
                    >
                        LOG IND MED DISCORD
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Login;
