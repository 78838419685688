import { useEffect, useMemo, useRef, useCallback, useState } from 'react';

export default function Dice({ number }) {
    const [fakeNumber, setFakeNumber] = useState(0);
    const started = useRef(null);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        let counter;
        if (toggle) {
            counter = setInterval(() => setFakeNumber(Math.floor(Math.random() * 6 + 1)), 50);
            started.current = new Date();
        }
        return () => {
            clearInterval(counter);
            setFakeNumber(null);
        };
    }, [toggle]);

    useEffect(() => {
        setToggle(!!number);
    }, [number]);

    useEffect(() => {
        if (new Date() - started.current > 1500) {
            setToggle(false);
        }
    }, [fakeNumber]);

    return (
        <img
            src={'/assets/dice-' + (fakeNumber || number || '0') + '.png'}
            style={{
                height: '72px',
                width: '72px',
            }}
            alt={'dice'}
        />
    );
}
