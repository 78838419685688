import { Icon } from '@mui/material';
import React, { useMemo } from 'react';
import clsx from 'clsx';

function UnoCard({ card, index, hover, onClick, noPosition, show, pledged, onPledged, money }) {
    const color = useMemo(() => {
        if (card.substring(0, 1) === 'W') {
            switch (card.substring(7, 8) || card.substring(3, 4)) {
                case 'R':
                    return 'red';
                case 'G':
                    return 'green';
                case 'B':
                    return 'blue';
                case 'Y':
                    return 'yellow';
                default:
                    return 'black';
            }
        }
        switch (card.substring(0, 1)) {
            case 'R':
                return 'red';
            case 'G':
                return 'green';
            case 'B':
                return 'blue';
            case 'Y':
                return 'yellow';
            default:
                return 'black';
        }
    }, [card]);

    const number = useMemo(() => {
        if (card === 'Wuno') {
            return 'uno';
        }
        if (card.substring(1, 3) === '+2') {
            return 'plus2';
        }
        if (card.substring(1, 5) === 'skip') {
            return 'skip';
        }
        if (card.substring(1, 8) === 'reverse') {
            return 'reverse';
        }
        if (card.substring(0, 1) === 'W') {
            if (card.substring(1, 3) === '+4') {
                return 'wild4';
            }
            return 'wild';
        }
        return card.substring(1, 2);
    }, [card]);

    return (
        <div
            className={clsx(
                'card',
                `num-${number}`,
                (color === 'black' || (number !== 'wild4' && number !== 'wild')) && color,
                number === 'wild4' && 'black'
            )}
        >
            <span className="inner">
                {number === 'uno' && (
                    <span className="mark red">
                        <div
                            className="text-yellow-300"
                            style={{
                                transform: 'rotate(-60deg)',
                                fontSize: '3.4rem',
                                margin: '2rem -1.75rem',
                            }}
                        >
                            UNO
                        </div>
                    </span>
                )}
                {number !== 'uno' &&
                    number !== 'plus2' &&
                    number !== 'skip' &&
                    number !== 'reverse' &&
                    number !== 'wild' &&
                    number !== 'wild4' && <span className="mark">{number}</span>}
                {number === 'plus2' && (
                    <span className="mark">
                        <div className={'card2-card'} />
                        <div className={'card2-card'} />
                    </span>
                )}
                {number === 'wild4' && (
                    <span className="mark">
                        <div
                            className={clsx(
                                'card4-card',
                                color !== 'black' && color !== 'blue' && 'opacity-10'
                            )}
                        />
                        <div
                            className={clsx(
                                'card4-card',
                                color !== 'black' && color !== 'green' && 'opacity-10'
                            )}
                        />
                        <div
                            className={clsx(
                                'card4-card',
                                color !== 'black' && color !== 'red' && 'opacity-10'
                            )}
                        />
                        <div
                            className={clsx(
                                'card4-card',
                                color !== 'black' && color !== 'yellow' && 'opacity-10'
                            )}
                        />
                    </span>
                )}
                {number === 'wild' && (
                    <div className={'w-full h-full flex flex-wrap'}>
                        <div
                            className={clsx(
                                'wild yellow',
                                color !== 'black' && color !== 'yellow' && 'opacity-30'
                            )}
                        />
                        <div
                            className={clsx(
                                'wild green',
                                color !== 'black' && color !== 'green' && 'opacity-30'
                            )}
                        />
                        <div
                            className={clsx(
                                'wild blue',
                                color !== 'black' && color !== 'blue' && 'opacity-30'
                            )}
                        />
                        <div
                            className={clsx(
                                'wild red',
                                color !== 'black' && color !== 'red' && 'opacity-30'
                            )}
                        />
                    </div>
                )}
                {number === 'skip' && (
                    <span className="mark">
                        <Icon
                            style={{ fontSize: '4rem', margin: '0 -0.6rem' }}
                            fontSize={'inherit'}
                        >
                            blocked
                        </Icon>
                    </span>
                )}
                {number === 'reverse' && (
                    <span className="mark">
                        <div className="arrows">
                            <div className="arrow" />
                            <div className="arrow" />
                        </div>
                    </span>
                )}
            </span>
        </div>
    );
}

export default UnoCard;
