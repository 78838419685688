import {
    Avatar,
    Button,
    Icon,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Color3 } from '@babylonjs/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSocketByNamespace,
    selectSocketLoaded,
    setupSockets,
    socketListener,
} from '../store/socketSlice';
import { selectUser } from '../store/authSlice';
import Logo from '../ui-components/Logo';
import gameType from '../gameType';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const numberFormatter = new Intl.NumberFormat('da-DK');

function End({ gameMode }) {
    const user = useSelector(selectUser);
    const [players, setPlayers] = useState([]);
    const navigate = useNavigate();
    const { lobbyId } = useParams();
    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    useEffect(() => {
        if (lobbyId) {
            apiService.request('/game/' + gameMode + '/game/' + lobbyId).then((response) => {
                setPlayers(response.players);
                if (response.game.state === 'STARTED') {
                    navigate('/game/' + response.lobbyId);
                } else if (response.game.state === 'LOBBY') {
                    navigate('/lobby/' + response.lobbyId);
                }
            });
        }
    }, [gameMode, lobbyId, navigate, user]);

    function handleCreateNew() {
        navigate('/lobby');
    }

    const sortedPlayers = useMemo(() => {
        if (gameMode === gameType.MATADOR) {
            const playersWithMoney = players.filter((player) => player.money >= 0);
            if (playersWithMoney.length > 1) {
                return players.sort((a, b) => b.money - a.money);
            }
            return players.sort((a, b) => a.order_id - b.order_id);
        } else {
            return players.sort((a, b) => (a.finished || 9999) - (b.finished || 9999));
        }
    }, [gameMode, players]);

    return (
        <div className={'bg-gray-900 py-10 w-screen h-screen'}>
            <div className={'flex justify-center py-10'}>
                <Logo />
            </div>
            <div className={'max-w-4xl m-auto mt-32'}>
                <Table>
                    <TableHead className={'border-0'}>
                        <TableRow className={'border-0'}>
                            <TableCell className={'border-0'}>#</TableCell>
                            <TableCell className={'border-0'}>Player</TableCell>
                            {gameMode === gameType.UNO && (
                                <>
                                    <TableCell className={'border-0 text-center'}>
                                        <Tooltip title={'Total cards drawn'}>
                                            <Icon fontSize={'inherit'} className={'text-3xl'}>
                                                style
                                            </Icon>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={'border-0 text-center'}>
                                        <Tooltip title={'Amount of times called UNO'}>
                                            <FontAwesomeIcon size={'2x'} icon={['fas', 'fa-1']} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={'border-0 text-center'}>
                                        <Tooltip title={'+2/+4 cards used'}>
                                            <FontAwesomeIcon
                                                size={'2x'}
                                                icon={['fas', 'fa-plus']}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={'border-0 text-center'}>
                                        <Tooltip title={'Skip cards used'}>
                                            <FontAwesomeIcon size={'2x'} icon={['fas', 'fa-ban']} />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={'border-0 text-center'}>
                                        <Tooltip title={'Reverse cards used'}>
                                            <FontAwesomeIcon
                                                size={'2x'}
                                                icon={['fas', 'fa-backward']}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={'border-0 text-center'}>
                                        <Tooltip title={'Wild cards used'}>
                                            <FontAwesomeIcon
                                                size={'2x'}
                                                icon={['fas', 'fa-palette']}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPlayers.map((player, index) => (
                            <TableRow
                                key={player.id}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.background.paper,
                                    border: '1px solid rgba(255, 255, 255, 0.12)',
                                }}
                            >
                                <TableCell className={clsx('mr-10 font-bold text-3xl border-0')}>
                                    #{index + 1}
                                </TableCell>
                                <TableCell className={'border-0'}>
                                    <div className={'flex items-center'}>
                                        <Avatar
                                            src={
                                                player.avatar
                                                    ? `https://cdn.discordapp.com/avatars/${player.user_id}/${player.avatar}.png`
                                                    : `https://cdn.discordapp.com/embed/avatars/${
                                                          player.discriminator % 5
                                                      }.png`
                                            }
                                            className={
                                                'w-44 h-44 border-2 border-' + player.color + '-400'
                                            }
                                        />
                                        <Typography
                                            className={
                                                'ml-10 font-bold text-xl ' +
                                                (player.ready
                                                    ? player.textColor
                                                        ? 'text-' + player.textColor
                                                        : 'text-black'
                                                    : 'text-white')
                                            }
                                        >
                                            {player.username}
                                        </Typography>
                                    </div>
                                </TableCell>
                                {gameMode === gameType.UNO && (
                                    <>
                                        <TableCell className={'border-0 text-xl text-center'}>
                                            {player.cardsDrawn || '0'}
                                        </TableCell>
                                        <TableCell className={'border-0 text-xl text-center'}>
                                            {player.calledUno || '0'}
                                        </TableCell>
                                        <TableCell className={'border-0 text-xl text-center'}>
                                            {player.plusCards || '0'}/{player.plusCardsWild || '0'}
                                        </TableCell>
                                        <TableCell className={'border-0 text-xl text-center'}>
                                            {player.reverseCards || '0'}
                                        </TableCell>
                                        <TableCell className={'border-0 text-xl text-center'}>
                                            {player.skipCards || '0'}
                                        </TableCell>
                                        <TableCell className={'border-0 text-xl text-center'}>
                                            {player.wildCards || '0'}
                                        </TableCell>
                                    </>
                                )}
                                {gameMode === gameType.MATADOR && (
                                    <>
                                        {player.money >= 0 && (
                                            <TableCell>
                                                <Typography className={'mr-10 font-bold text-xl'}>
                                                    {numberFormatter.format(player.money / 100)},-
                                                </Typography>
                                            </TableCell>
                                        )}
                                        {player.money < 0 && (
                                            <TableCell>
                                                <Typography className={'mr-10 font-bold text-xl'}>
                                                    KONKURS
                                                </Typography>
                                            </TableCell>
                                        )}
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className={'flex justify-around mt-32'}>
                    <Button
                        disabled={players.length <= 1}
                        color={playerSelf?.ready ? 'error' : 'success'}
                        variant={playerSelf?.ready ? 'outlined' : 'contained'}
                        className={'w-1/3'}
                        onClick={handleCreateNew}
                    >
                        OPRET NY LOBBY
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default End;
