import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from '../store/authSlice';
import Logo from '../ui-components/Logo';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { getGameType } from '../utilities';
import gameType from '../gameType';
import { Button } from '@mui/material';

function Home({ gameMode }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    function handleLogOut() {
        apiService.logout().then(() => {
            dispatch(setUser(null));
        });
    }

    async function handleCreate() {
        if (user?.discordUser || gameMode === gameType.UNO) {
            if (gameMode === gameType.UNO && !user?.discordUser) {
                await apiService.loginAnonymously();
            }
            const response = await apiService.request(
                '/game/' + gameMode.toLowerCase() + '/create',
                'POST',
                {
                    password: null,
                }
            );
            navigate('/lobby/' + response.lobbyId);
        } else {
            navigate('/login');
        }
    }

    return (
        <div className={'bg-gray-900 py-10 w-screen h-screen'}>
            <div className={'flex justify-center items-center h-full'}>
                <div className={'flex flex-col items-center justify-center'}>
                    <div className={'flex items-center'}>
                        <Logo />
                    </div>
                    {!user && gameMode === gameType.MATADOR && (
                        <Button
                            variant="contained"
                            className={'mt-16 px-24 py-8 text-lg'}
                            onClick={() => navigate('/login')}
                        >
                            Log ind
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        className={'mt-16 px-24 py-8 text-lg'}
                        onClick={handleCreate}
                    >
                        {gameMode === gameType.MATADOR ? 'Opret lobby' : 'Create lobby'}
                    </Button>
                    <Button
                        variant="contained"
                        className={'mt-16 px-24 py-8 text-lg'}
                        onClick={() => window.open('https://discord.gg/pwhQPTFe3F', '_blank')}
                    >
                        Join Discord
                    </Button>
                    {user && gameMode === gameType.MATADOR && (
                        <Button
                            variant="contained"
                            className={'mt-16 px-24 py-8 text-lg'}
                            onClick={handleLogOut}
                        >
                            Log ud
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
