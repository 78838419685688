import { createSlice } from '@reduxjs/toolkit';
import { io } from 'socket.io-client';

const socketSlice = createSlice({
    name: 'socket',
    initialState: {
        socket: {},
        socketLoaded: false,
        lobbyId: null,
    },
    reducers: {
        setSocket: (state, payload) => {
            state.socket[payload.payload.id] = payload.payload.socket;
        },
        setSocketLoaded: (state, payload) => {
            state.socketLoaded = payload.payload;
        },
        setLobbyId: (state, payload) => {
            state.lobbyId = payload.payload;
        },
    },
});

export const { setSocket, setSocketLoaded, setLobbyId } = socketSlice.actions;

export const setupSockets = (lobbyId, gameMode) => async (dispatch) => {
    dispatch(setLobbyId(lobbyId));
    dispatch(
        setSocket({
            id: gameMode,
            socket: io(
                process.env.NODE_ENV === 'development'
                    ? 'http://localhost:3918/' + gameMode
                    : document.location.origin + '/' + gameMode,
                {
                    query:
                        'lobbyId=' +
                        lobbyId +
                        (localStorage.getItem('jwt_access_token')
                            ? '&auth_token=' + localStorage.getItem('jwt_access_token')
                            : ''),
                    path: process.env.NODE_ENV === 'development' ? '/socket.io' : '/socket.io',
                }
            ),
        })
    );
    dispatch(setSocketLoaded(true));
};

export const socketListener = (namespace, event, callback) => (dispatch, getState) => {
    const { socket } = getState();
    const handler = (resp) => callback(resp);
    socket.socket[namespace].on(event, handler);

    return () => {
        socket.socket[namespace].off(event, handler);
    };
};

export default socketSlice.reducer;

export const selectLobbyId = (state) => state.socket.lobbyId;
export const selectSocketLoaded = (state) => state.socket.socketLoaded;
export const selectSocketByNamespace = (state, namespace) =>
    state.socket.socket?.[namespace] || null;
