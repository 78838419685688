import { createSlice } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        loaded: false,
    },
    reducers: {
        setUser: (state, payload) => {
            state.user = payload.payload;
        },
        setLoaded: (state, payload) => {
            state.loaded = payload.payload;
        },
    },
});

export const { setUser, setLoaded } = authSlice.actions;

export const setupAuth = () => async (dispatch) => {
    const response = await apiService.verify();
    dispatch(setUser(response));
    dispatch(setLoaded(true));
};

export default authSlice.reducer;

export const selectLoaded = (state) => state.auth.loaded;
export const selectUser = (state) => state.auth.user;
