import { selectLoaded, setupAuth } from '../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import Logo from '../ui-components/Logo';
import Button from '../ui-components/Button';
import React from 'react';
import { styled } from 'styled-components';

const Title = styled.p`
    font-size: 150px;
    line-height: 0;
    color: #fff;
    text-shadow: -1px 5px 0 rgba(69, 248, 130, 0.66);
    font-family: 'berlin_sans_fb_demibold';

    @media (max-width: 800px) {
        font-size: 100px;
    }
    @media (max-width: 600px) {
        font-size: 50px;
    }
`;
function Auth({ children }) {
    const once = useRef(false);
    const dispatch = useDispatch();
    const loaded = useSelector(selectLoaded);
    const matches = useMediaQuery('(max-width:1200px)');

    useEffect(() => {
        dispatch(setupAuth());
    }, [dispatch]);

    if (!loaded) {
        return 'Indlæser...';
    }

    if (matches) {
        return (
            <div className={'bg-gray-900 py-10 w-screen h-screen'}>
                <div className={'flex justify-center items-center h-full'}>
                    <div className={'flex flex-col items-center justify-center'}>
                        <div className={'flex items-center'}>
                            <Title>Desværre</Title>
                        </div>
                        <Typography
                            className={
                                'text-white text-center px-20 text-md mt-10 md:-mt-20 mb-24 sm:text-lg md:text-xl '
                            }
                        >
                            Siden er desværre ikke mobilvenlig, men det kan jo være det sker i
                            fremtiden
                        </Typography>
                        <Button
                            onClick={() => window.open('https://discord.gg/pwhQPTFe3F', '_blank')}
                        >
                            Join Discord
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return children;
}

export default Auth;
