import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

const numberFormatter = new Intl.NumberFormat('da-DK');

export default function LogEntry({ log }) {
    const data = React.useMemo(() => {
        const _data = JSON.parse(log.data);
        if (log.type === 'MONEY-CHANGE') {
            return {
                primary: 'Penge',
                secondary:
                    _data.amount > 0
                        ? _data.player.username +
                          ' fik ' +
                          numberFormatter.format(Math.abs(_data.amount) / 100) +
                          ',-'
                        : _data.player.username +
                          ' mistede ' +
                          numberFormatter.format(Math.abs(_data.amount) / 100) +
                          ',-',
                avatar: _data.player.avatar
                    ? `https://cdn.discordapp.com/avatars/${_data.player.user_id}/${_data.player.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${
                          _data.player.discriminator % 5
                      }.png`,
                color: _data.player.color,
            };
        }
        if (log.type === 'TURN') {
            return {
                primary: 'Næste tur',
                secondary: 'Det er nu ' + _data.nextPlayer.username,
                avatar: _data.nextPlayer.avatar
                    ? `https://cdn.discordapp.com/avatars/${_data.nextPlayer.user_id}/${_data.nextPlayer.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${
                          _data.nextPlayer.discriminator % 5
                      }.png`,
                color: _data.nextPlayer.color,
            };
        }
        if (log.type === 'THROW') {
            return {
                primary: 'Terningskast',
                secondary: _data.player.username + ' slog ' + _data.dice1 + ' & ' + _data.dice2,
                avatar: _data.player.avatar
                    ? `https://cdn.discordapp.com/avatars/${_data.player.user_id}/${_data.player.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${
                          _data.player.discriminator % 5
                      }.png`,
                color: _data.player.color,
            };
        }
        if (log.type === 'GROUND-CHANGE') {
            return {
                primary: 'Bil kørt',
                secondary: _data.player.username + ' er kørt hen på ' + _data.ground.name,
                avatar: _data.player.avatar
                    ? `https://cdn.discordapp.com/avatars/${_data.player.user_id}/${_data.player.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${
                          _data.player.discriminator % 5
                      }.png`,
                color: _data.player.color,
            };
        }
        if (log.type === 'GROUND-BOUGHT') {
            return {
                primary: 'Grund købt',
                secondary:
                    _data.player.username +
                    ' købte grunden ' +
                    _data.ground.name +
                    ' for ' +
                    numberFormatter.format(_data.ground.buyPrice) +
                    ' kr.',
                avatar: _data.player.avatar
                    ? `https://cdn.discordapp.com/avatars/${_data.player.user_id}/${_data.player.avatar}.png`
                    : `https://cdn.discordapp.com/embed/avatars/${
                          _data.player.discriminator % 5
                      }.png`,
                color: _data.player.color,
            };
        }
        return { primary: '', secondary: '', avatar: '', color: 'red' };
    }, [log]);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar
                    src={data.avatar}
                    sx={{
                        border: (theme) => '3px solid ' + theme.palette[data.color].main,
                    }}
                    className={clsx('w-24 h-24')}
                />
            </ListItemAvatar>
            <ListItemText
                sx={{ color: '#ffffff' }}
                primary={data.primary}
                secondary={data.secondary}
            />
        </ListItem>
    );
}
