import { combineReducers } from '@reduxjs/toolkit';
import auth from './authSlice';
import socket from './socketSlice';

const createReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        socket,
        ...asyncReducers,
    });

    return combinedReducer(state, action);
};

export default createReducer;
