import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import {
    Backdrop,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Fade,
    Grid,
    Icon,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import GroundCard from './GroundCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import { selectSocketByNamespace, selectSocketLoaded, socketListener } from '../store/socketSlice';
import {
    addLog,
    selectCurrentPlayer,
    selectCurrentThrow,
    selectPlayers,
    selectSellFor,
    setCurrentPlayer,
    setCurrentThrow,
    setGame,
    setPlayers,
    setQuestionDialog,
    setSellFor,
    addSellOffer,
    selectSelectedSellOffer,
    setSelectedSellOffer,
    removeSellOffer,
} from './gameSlice';
import Dice from './Dice';
import { createPortal } from 'react-dom';
import FullscreenCards from './FullscreenCards';
import { styled } from 'styled-components';
import CustomHR from '../ui-components/CustomHR';
import SellGroundsToOther from './SellGroundsToOther';

function Cards() {
    const dispatch = useDispatch();
    const socketLoaded = useSelector(selectSocketLoaded);
    const players = useSelector(selectPlayers);
    const currentPlayer = useSelector(selectCurrentPlayer);
    const currentThrow = useSelector(selectCurrentThrow);
    const user = useSelector(selectUser);
    const selectedSellOffer = useSelector(selectSelectedSellOffer);
    const [disableNextTurn, setDisableNextTurn] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [clickedCard, setClickedCard] = useState(null);
    const [confirmNextTurn, setConfirmNextTurn] = useState(false);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'matador'));
    const endTurnCooldownTimerRef = useRef(null);
    const [endTurnCooldownTime, setEndTurnCooldownTime] = useState(1);
    const [sellToOtherDialog, setSellToOtherDialog] = React.useState(false);
    const rentCooldownTimerRef = useRef(null);
    const [rentCooldownTime, setRentCooldownTime] = useState(0);

    useEffect(() => {
        if (socketLoaded) {
            const playerSellUnsubListener = dispatch(
                socketListener('matador', 'player-sell', (response) => {
                    if (response.method === 'add') {
                        dispatch(addSellOffer(response));
                    } else if (response.method === 'remove') {
                        dispatch(removeSellOffer(response));
                    }
                })
            );
            return () => {
                playerSellUnsubListener();
            };
        }
        return () => {};
    }, [dispatch, socketLoaded]);

    useEffect(() => {
        if (confirmNextTurn) {
            endTurnCooldownTimerRef.current = setInterval(() => {
                setEndTurnCooldownTime((oldState) => (oldState > 0 ? oldState - 1 : 0));
            }, 1000);
            return () => {
                clearInterval(endTurnCooldownTimerRef.current);
                setEndTurnCooldownTime(1);
            };
        }
    }, [confirmNextTurn]);

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    useEffect(() => {
        let counter;
        if (currentThrow) {
            setDisableNextTurn(true);
            counter = setTimeout(() => setDisableNextTurn(false), 1500);
        }
        return () => {
            clearInterval(counter);
            setDisableNextTurn(false);
        };
    }, [currentThrow]);

    const canThrowDice = useMemo(() => {
        if (!currentThrow) {
            return true;
        }
        return currentThrow.sameThrow && currentThrow.sameThrowAmount <= 2;
    }, [currentThrow]);

    const cards = useMemo(() => {
        if (!playerSelf?.cards) {
            return null;
        }
        let _cards = [...playerSelf.cards];
        _cards.sort((a, b) => a.number - b.number);
        for (let i = 1; i <= playerSelf.unjailCards; i++) {
            _cards.push({
                name: 'Løsladelseskort',
                special: 'UNJAIL',
                color: 'primary',
                number: 'unjail-' + i,
            });
        }
        return _cards;
    }, [playerSelf]);
    const isMyTurn = currentPlayer?.user_id === user?.discordUser?.id;

    function handleDiceRoll() {
        socket.emit('current-throw', {});
    }

    function handleEndTurn() {
        setConfirmNextTurn(false);
        socket.emit('end-of-turn', {});
    }

    function handleCollectRent() {
        if (rentCooldownTime > 0) {
            return;
        }
        clearInterval(rentCooldownTimerRef.current);
        setRentCooldownTime(5);
        rentCooldownTimerRef.current = setInterval(() => {
            setRentCooldownTime((oldState) => (oldState > 0 ? oldState - 1 : 0));
        }, 1000);
        socket.emit('collect-rent', {});
    }

    function handleSell(_ground) {
        const playerCard = playerSelf.cards.find((x) => x.number === _ground);
        if (playerCard.isPanted) {
            socket.emit('rebuy-grounds', {
                grounds: [_ground],
            });
        } else {
            socket.emit('sell-grounds', {
                grounds: [_ground],
            });
        }
    }

    function handleToggleFullscreen() {
        setFullscreen((oldState) => !oldState);
    }

    return (
        <div
            style={{
                position: 'absolute',
                overflowY: 'auto',
                overflowX: 'hidden',
                right: 0,
                top: 0,
                height: '100%',
                width: '275px',
                marginRight: '-8px',
            }}
        >
            <Paper
                className={'w-full h-full rounded-none'}
                elevation={10}
                sx={{ bgcolor: '#0d1116' }}
            >
                <div style={{ backgroundColor: '#0d1116' }} className={'py-10'}>
                    <div className={'flex items-center justify-center'}>
                        <Dice number={currentThrow?.dice1 || null} />
                        <Dice number={currentThrow?.dice2 || null} />
                    </div>
                    <div className={'px-8 pb-4 pt-4'}>
                        {(!isMyTurn || canThrowDice) && (
                            <Button
                                className={'w-full py-4 rounded-none'}
                                variant={'contained'}
                                color={'success'}
                                onClick={handleDiceRoll}
                                disabled={!isMyTurn || playerSelf?.jailed > 0}
                            >
                                RUL MED TERNINGERNE
                            </Button>
                        )}
                        {isMyTurn && !canThrowDice && (
                            <>
                                {!confirmNextTurn && (
                                    <Button
                                        className={'w-full py-4 rounded-none'}
                                        variant={'contained'}
                                        color={'success'}
                                        onClick={() => setConfirmNextTurn(true)}
                                        disabled={disableNextTurn}
                                    >
                                        AFSLUT TUR
                                    </Button>
                                )}
                                {confirmNextTurn && (
                                    <ButtonGroup className={'w-full rounded-none'}>
                                        <Button
                                            className={'w-full'}
                                            variant={'contained'}
                                            color={'error'}
                                            onClick={() => setConfirmNextTurn(false)}
                                        >
                                            ANNULLER
                                        </Button>
                                        <Button
                                            className={'w-full'}
                                            variant={'contained'}
                                            color={'success'}
                                            onClick={handleEndTurn}
                                            disabled={!!endTurnCooldownTime}
                                        >
                                            AFSLUT
                                            {endTurnCooldownTime > 0 &&
                                                '(' + endTurnCooldownTime + 's)'}
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </>
                        )}
                        <Button
                            className={'w-full mt-4 py-4 rounded-none'}
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={handleCollectRent}
                            disabled={!!playerSelf?.rentCollected || rentCooldownTime > 0}
                        >
                            OPKRÆV LEJE
                            {rentCooldownTime > 0 && ' (' + rentCooldownTime + 's)'}
                        </Button>
                    </div>
                </div>
                <CustomHR />
                <div className={'relative'}>
                    <Typography className={'text-center pt-2 text-white font-bold'} variant={'h5'}>
                        Dine grunde
                    </Typography>
                    <div className={'absolute right-6 top-0'}>
                        <Tooltip title={'Vis alle kort i fuldskærm'}>
                            <IconButton size={'small'} onClick={handleToggleFullscreen}>
                                <Icon>fullscreen</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ position: 'relative' }}>
                    {cards?.map((card, index) => (
                        <React.Fragment key={'card-' + card.number}>
                            <GroundCard
                                card={card}
                                number={index}
                                hover={clickedCard}
                                onClick={(val) => setClickedCard(val)}
                                pledged={card.isPanted}
                                onPledged={() => handleSell(card.number)}
                                money={playerSelf?.money}
                            />
                        </React.Fragment>
                    ))}
                </div>
                <FullscreenCards open={fullscreen} onClose={handleToggleFullscreen} />
                {(sellToOtherDialog || selectedSellOffer) && (
                    <SellGroundsToOther
                        offer={selectedSellOffer}
                        onClose={() => {
                            setSellToOtherDialog(false);
                            dispatch(setSelectedSellOffer(null));
                        }}
                    />
                )}
            </Paper>
        </div>
    );
}

export default Cards;
