import { createPortal } from 'react-dom';
import {
    Backdrop,
    Button,
    ClickAwayListener,
    DialogTitle,
    Fade,
    Grid,
    Icon,
    IconButton,
    Paper,
    Typography,
} from '@mui/material';
import GroundCard from './GroundCard';
import React, { useEffect, useMemo, useState } from 'react';
import { selectSocketByNamespace, selectSocketLoaded, socketListener } from '../store/socketSlice';
import { selectPlayers, selectSellFor, setSellFor } from './gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import CustomHR from '../ui-components/CustomHR';

const numberFormatter = new Intl.NumberFormat('da-DK');

export default function FullscreenCards({ open, onClose }) {
    const dispatch = useDispatch();
    const socketLoaded = useSelector(selectSocketLoaded);
    const [selectedPledged, setSelectedPledged] = useState([]);
    const sellFor = useSelector(selectSellFor);
    const players = useSelector(selectPlayers);
    const user = useSelector(selectUser);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'matador'));

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    const cards = useMemo(() => {
        if (!playerSelf?.cards) {
            return null;
        }
        let _cards = [...playerSelf.cards];
        _cards.sort((a, b) => a.number - b.number);
        return _cards;
    }, [playerSelf]);

    const selectedCards = useMemo(() => {
        if (!cards) {
            return [];
        }
        return cards.filter((card) => selectedPledged.includes(card.number));
    }, [cards, selectedPledged]);

    const needs = useMemo(() => {
        if (!playerSelf) {
            return sellFor;
        }
        return (
            playerSelf.money -
            sellFor +
            selectedCards.reduce(
                (acc, card) =>
                    acc +
                    (card.pant +
                        (card?.houses || 0) * (card?.buyHousePrice?.[0] || 0) +
                        (card?.hotels || 0) * (card?.buyHousePrice?.[1] || 0)),
                0
            ) *
                100
        );
    }, [sellFor, playerSelf, selectedCards]);

    useEffect(() => {
        if (socketLoaded) {
            const notEnoughMoneyUnsubListener = dispatch(
                socketListener('matador', 'not-enough-money', (response) => {
                    dispatch(setSellFor(response.needsToPay));
                })
            );
            return () => {
                notEnoughMoneyUnsubListener();
            };
        }
        return () => {};
    }, [dispatch, socketLoaded]);

    function handleSell(_grounds) {
        socket.emit('sell-grounds', {
            grounds: _grounds,
        });
    }

    function handleClose() {
        if (sellFor) {
            if (needs >= 0) {
                handleSell(selectedCards.map((card) => card.number));
                dispatch(setSellFor(null));
                setSelectedPledged([]);
            }
        }
        onClose();
    }

    function handlePledged(card) {
        if (sellFor) {
            setSelectedPledged((oldState) => {
                if (oldState.includes(card.number)) {
                    return oldState.filter((x) => x !== card.number);
                }
                return [...oldState, card.number];
            });
        } else {
            const playerCard = playerSelf.cards.find((x) => x.number === card.number);
            if (playerCard.isPanted) {
                socket.emit('rebuy-grounds', {
                    grounds: [card.number],
                });
            } else {
                handleSell([card.number]);
            }
        }
    }

    return (
        <>
            {(open || !!sellFor) &&
                createPortal(
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <div
                            className={'fixed'}
                            style={{
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 9999,
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <Fade in>
                                    <Paper
                                        className={'min-w-xl h-full rounded-none relative'}
                                        elevation={10}
                                        style={{
                                            minHeight: '25rem',
                                        }}
                                    >
                                        <div
                                            className={'pb-10'}
                                            style={{
                                                backgroundColor: '#121a23',
                                            }}
                                        >
                                            {sellFor && (
                                                <div className={'absolute left-10 top-10'}>
                                                    <div>
                                                        <Typography className={''}>
                                                            <span className={'font-bold'}>
                                                                Din konto:
                                                            </span>{' '}
                                                            {numberFormatter.format(
                                                                playerSelf.money / 100
                                                            )}
                                                            ,-
                                                        </Typography>
                                                        <Typography className={''}>
                                                            <span className={'font-bold'}>
                                                                Mangler:
                                                            </span>{' '}
                                                            <span
                                                                className={
                                                                    needs > 0
                                                                        ? 'text-green-500'
                                                                        : 'text-red-500'
                                                                }
                                                            >
                                                                {numberFormatter.format(
                                                                    needs / 100
                                                                )}
                                                                ,-
                                                            </span>
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )}
                                            <Typography
                                                className={'text-center pt-10 font-bold text-white'}
                                                variant={'h4'}
                                            >
                                                Dine grunde
                                            </Typography>
                                            {!sellFor && (
                                                <div className={'absolute right-10 top-10'}>
                                                    <IconButton
                                                        size={'small'}
                                                        onClick={handleClose}
                                                    >
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                </div>
                                            )}
                                            {sellFor && (
                                                <div className={'absolute right-10 top-10'}>
                                                    <Button
                                                        variant={'contained'}
                                                        color={'success'}
                                                        onClick={handleClose}
                                                        disabled={needs < 0}
                                                    >
                                                        Færdiggør
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                        <CustomHR />
                                        <div
                                            className={'p-10  overflow-y-scroll'}
                                            style={{
                                                height: 'calc(100vh - 15rem)',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                {cards?.map((card) => (
                                                    <Grid item sm={2} key={'card-' + card.number}>
                                                        <GroundCard
                                                            card={card}
                                                            number={0}
                                                            hover={null}
                                                            noPosition
                                                            pledged={
                                                                selectedPledged.includes(
                                                                    card.number
                                                                ) || card.isPanted
                                                            }
                                                            onPledged={() => {
                                                                handlePledged(card);
                                                            }}
                                                            money={playerSelf?.money}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    </Paper>
                                </Fade>
                            </ClickAwayListener>
                        </div>
                    </Backdrop>,
                    document.getElementById('root')
                )}
        </>
    );
}
