import { styled } from 'styled-components';
import { useTheme } from '@mui/material';

const CustomBox = styled.div`
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
    background-color: #121a23;
    background-image: -moz-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
    background-image: -webkit-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
    background-image: -ms-linear-gradient(90deg, #0c0e12 0%, rgba(31, 41, 53, 0.36078) 100%);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.borderColor || 'rgba(76, 76, 76, 0.2)'};
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    color: #fff;
`;

export default function Box({ children, className, borderColor, ...props }) {
    const theme = useTheme();

    return (
        <CustomBox
            className={className}
            borderColor={borderColor ? theme.palette[borderColor].main : null}
            {...props}
        >
            {children}
        </CustomBox>
    );
}
