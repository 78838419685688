import '@babylonjs/loaders/glTF';
import MatadorGame from './matador/Game';
import UNOGame from './uno/Game';
import Lobby from './lobby/Lobby';
import End from './end/End';
import { createTheme, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './login/Login';
import Auth from './services/Auth';
import store from './store';
import Provider from 'react-redux/es/components/Provider';
import * as BABYLON from '@babylonjs/core';
import Home from './home/Home';
import gameType from './gameType';
import { getGameType } from './utilities';

const dark = {
    50: '#e5e6e8',
    100: '#bec1c5',
    200: '#92979f',
    300: '#666d78',
    400: '#464e5b',
    500: '#252f3e',
    600: '#212a38',
    700: '#1b2330',
    800: '#161d28',
    900: '#0d121b',
    A100: '#5d8eff',
    A200: '#2a6aff',
    A400: '#004af6',
    A700: '#0042dd',
    contrastDefaultColor: 'light',
};

const darkText = {
    primary: '#ffffff',
    secondary: '#adb0bc',
    disabled: '#7c7f8a',
};

function App() {
    const theme = createTheme({
        palette: {
            mode: 'dark',
            text: darkText,
            primary: {
                main: '#45F882',
            },
            secondary: {
                main: '#FFBE18',
            },
            background: {
                paper: '#1B242E',
                default: '#10181F',
            },
            red: {
                main: '#ff0000',
            },
            pink: {
                main: '#ff69b4',
            },
            black: {
                main: '#000000',
            },
            blue: {
                main: '#000dff',
            },
            purple: {
                main: '#8c00ff',
            },
            white: {
                main: '#ffffff',
            },
            orange: {
                main: '#ff9100',
            },
            green: {
                main: '#08ff00',
            },
            gray: {
                main: '#656565',
            },
            dark_gray: {
                main: '#3d3d3d',
            },
            yellow: {
                main: '#ffef00',
            },
            light_blue: {
                main: '#00bbff',
            },
            light_red: {
                main: '#ff4e4e',
            },
            error: red,
        },
        status: {
            danger: 'orange',
        },
    });

    const gameMode = getGameType();
    document.querySelector('title').innerHTML = gameMode === gameType.MATADOR ? 'Matadoren' : 'UNO';

    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div className="app">
                        <Provider store={store}>
                            <Auth>
                                <Routes>
                                    <Route path="/" element={<Home gameMode={gameMode} />} />
                                    {gameMode === gameType.MATADOR && (
                                        <Route path="/login" element={<Login />} />
                                    )}
                                    <Route path="/lobby" element={<Lobby gameMode={gameMode} />} />
                                    <Route
                                        path="/lobby/:lobbyId"
                                        element={<Lobby gameMode={gameMode} />}
                                    />
                                    {gameMode === gameType.MATADOR && (
                                        <Route path="/game/:gameId" element={<MatadorGame />} />
                                    )}
                                    {gameMode === gameType.UNO && (
                                        <Route path="/game/:gameId" element={<UNOGame />} />
                                    )}
                                    <Route path="/end" element={<End gameMode={gameMode} />} />
                                    <Route
                                        path="/end/:lobbyId"
                                        element={<End gameMode={gameMode} />}
                                    />
                                </Routes>
                            </Auth>
                        </Provider>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    );
}

export default App;
