import SceneComponent from './babylon/SceneComponent';
import '@babylonjs/loaders/glTF';
import { useEffect, useRef } from 'react';
import {
    selectSocketByNamespace,
    selectSocketLoaded,
    setupSockets,
    socketListener,
} from '../store/socketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cards from './Cards';
import { Environment } from './babylon/environment';
import apiService from '../services/apiService';
import Players from './Players';
import withReducer from '../store/withReducer';
import reducer, {
    setCurrentPlayer,
    setCurrentThrow,
    setGame,
    setGrounds,
    setGroundView,
    setOfflinePlayers,
    setPlayers,
    setQuestionDialog,
} from './gameSlice';
import QuestionDialog from './QuestionDialog';

let cars = [];

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
const onRender = (scene) => {
    const deltaTimeInMillis = scene.getEngine().getDeltaTime();

    const rpm = 10;
    cars.forEach((car) => {
        car.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
    });
};

function Game() {
    const { gameId } = useParams();
    const dispatch = useDispatch();
    const socket = useSelector((state) => selectSocketByNamespace(state, 'matador'));
    const socketLoaded = useSelector(selectSocketLoaded);
    const once = useRef(false);
    const environment = useRef(null);

    const onSceneReady = async (scene) => {
        environment.current = new Environment(gameId, scene, socket, (_ground) =>
            dispatch(setGroundView(_ground))
        );
        await environment.current.load();
    };

    useEffect(() => {
        apiService
            .request('/game/matador/grounds')
            .then((resp) => {
                return resp.grounds;
            })
            .then((grounds) => {
                dispatch(setGrounds(grounds));
            });
    }, [dispatch]);

    useEffect(() => {
        if (gameId && !once.current) {
            once.current = true;
            dispatch(setupSockets(gameId));
        }
    }, [dispatch, gameId]);

    useEffect(() => {
        if (socketLoaded) {
            const playersUnsubListener = dispatch(
                socketListener('matador', 'players', (response) => {
                    console.log('players', response);
                    dispatch(setPlayers(response.players));
                    dispatch(setOfflinePlayers(response.offlinePlayers));
                })
            );
            const playerTurnChangeUnsubListener = dispatch(
                socketListener('matador', 'player-turn-change', (response) => {
                    dispatch(setCurrentPlayer(response.player));
                })
            );
            const gameUnsubListener = dispatch(
                socketListener('matador', 'game', (response) => {
                    dispatch(setGame(response.game));
                })
            );
            const rollUnsubListener = dispatch(
                socketListener('matador', 'current-throw', (response) => {
                    dispatch(setCurrentThrow(response));
                })
            );
            const questionDialogUnsubListener = dispatch(
                socketListener('matador', 'question-dialog', (response) => {
                    dispatch(setQuestionDialog(response));
                })
            );
            return () => {
                playersUnsubListener();
                playerTurnChangeUnsubListener();
                gameUnsubListener();
                rollUnsubListener();
                questionDialogUnsubListener();
            };
        }
        return () => {};
    }, [dispatch, socketLoaded]);

    return (
        <div>
            <div className="game flex">
                <SceneComponent
                    antialias
                    onSceneReady={onSceneReady}
                    onRender={onRender}
                    id="renderCanvas"
                    adaptToDeviceRatio
                />
                <Cards />
            </div>
            <Players />
            <QuestionDialog />
        </div>
    );
}

export default withReducer('game', reducer)(Game);
