import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import { selectSocketByNamespace } from '../store/socketSlice';
import { selectPlayers } from './gameSlice';
import CustomHR from '../ui-components/CustomHR';
import UnoCard from './UnoCard';
import clsx from 'clsx';

function ColorSelectDialog({ onClose }) {
    const dispatch = useDispatch();
    const players = useSelector(selectPlayers);
    const user = useSelector(selectUser);
    const socket = useSelector((state) => selectSocketByNamespace(state, 'uno'));
    const [color, setColor] = useState(null);

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    function handleButtonClick(result) {
        if (result) {
            onClose(color);
        } else {
            onClose();
        }
    }

    return (
        <Dialog
            sx={{
                '.MuiDialog-paper': {
                    bgcolor: '#1B242E',
                    color: (theme) => theme.palette.getContrastText('#1B242E'),
                    backgroundImage: 'unset',
                },
            }}
            onClose={() => handleButtonClick()}
            open
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle
                className={'text-center'}
                style={{
                    backgroundColor: '#121a23',
                }}
            >
                Select color
            </DialogTitle>
            <CustomHR />
            <DialogContent>
                <div
                    className={clsx('cursor-pointer', color && color !== 'R' && 'opacity-40')}
                    onClick={() => setColor('R')}
                >
                    <UnoCard card={'R'} />
                </div>
                <div
                    className={clsx('cursor-pointer', color && color !== 'B' && 'opacity-40')}
                    onClick={() => setColor('B')}
                >
                    <UnoCard card={'B'} />
                </div>
                <div
                    className={clsx('cursor-pointer', color && color !== 'G' && 'opacity-40')}
                    onClick={() => setColor('G')}
                >
                    <UnoCard card={'G'} />
                </div>
                <div
                    className={clsx('cursor-pointer', color && color !== 'Y' && 'opacity-40')}
                    onClick={() => setColor('Y')}
                >
                    <UnoCard card={'Y'} />
                </div>
                <ButtonGroup className={'mt-10 w-full'}>
                    <Button
                        variant={'contained'}
                        color={'error'}
                        fullWidth
                        className={'w-1/2'}
                        onClick={() => handleButtonClick(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'contained'}
                        fullWidth
                        className={'w-1/2'}
                        onClick={() => handleButtonClick(true)}
                        disabled={!color}
                    >
                        Select color
                    </Button>
                </ButtonGroup>
            </DialogContent>
        </Dialog>
    );
}

export default ColorSelectDialog;
