import { createSlice } from '@reduxjs/toolkit';

const gameSlice = createSlice({
    name: 'unoGame',
    initialState: {
        game: {},
        players: null,
        offlinePlayers: null,
        currentPlayer: null,
        logs: [],
        player: null,
    },
    reducers: {
        setGame: (state, payload) => {
            state.game = payload.payload;
        },
        setPlayers: (state, payload) => {
            state.players = payload.payload;
        },
        setOfflinePlayers: (state, payload) => {
            state.offlinePlayers = payload.payload;
        },
        setCurrentPlayer: (state, payload) => {
            state.currentPlayer = payload.payload;
        },
        setLogs: (state, payload) => {
            state.logs = payload.payload;
        },
        addLog: (state, payload) => {
            state.logs.unshift(payload.payload);
        },
        setPlayer: (state, payload) => {
            state.player = payload.payload;
        },
    },
});

export const {
    setGame,
    setPlayers,
    setCurrentPlayer,
    setLogs,
    addLog,
    setOfflinePlayers,
    setPlayer,
} = gameSlice.actions;

export default gameSlice.reducer;

export const selectGame = (state) => state.unoGame.game;
export const selectPlayers = (state) => state.unoGame.players;
export const selectOfflinePlayers = (state) => state.unoGame.offlinePlayers;
export const selectCurrentPlayer = (state) => state.unoGame.currentPlayer;
export const selectLogs = (state) => state.unoGame.logs;
export const selectPlayer = (state) => state.unoGame.player;
