import { Typography } from '@mui/material';
import React from 'react';
import clsx from 'clsx';

const numberFormatter = new Intl.NumberFormat('da-DK');

export default function PlayerMoney({ player }) {
    const oldMoney = React.useRef(null);
    const [diff, setDiff] = React.useState(null);

    React.useEffect(() => {
        const _diff = player.money - oldMoney.current;
        setDiff(_diff);
        oldMoney.current = player.money;
        let timeout = setTimeout(() => {
            setDiff(null);
        }, 2000);
        return () => {
            clearTimeout(timeout);
        };
    }, [player]);

    return (
        <>
            <Typography className={'text-sm'}>
                {numberFormatter.format(player.money / 100)},-
            </Typography>
            {diff !== null && diff !== 0 && (
                <div
                    className={clsx(
                        'absolute',
                        diff > 0 && 'text-green-400',
                        diff < 0 && 'text-red-400'
                    )}
                    style={{
                        top: '-15px',
                        right: '15px',
                        animation: 'custom-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
                    }}
                >
                    {diff > 0 ? '+' : '-'}
                    {numberFormatter.format(Math.abs(diff) / 100)},-
                </div>
            )}
        </>
    );
}
