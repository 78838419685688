import { useParams } from 'react-router-dom';
import withReducer from '../store/withReducer';
import Players from './Players';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSocketByNamespace,
    selectSocketLoaded,
    setupSockets,
    socketListener,
} from '../store/socketSlice';
import { useEffect, useRef } from 'react';
import reducer, {
    setCurrentPlayer,
    setGame,
    setOfflinePlayers,
    setPlayers,
    setPlayer,
    selectPlayer,
    selectCurrentPlayer,
    selectGame,
} from './gameSlice';
import UnoCard from './UnoCard';
import UnoCards from './UnoCards';
import { Button, ButtonGroup, Typography } from '@mui/material';
import clsx from 'clsx';
import { animated } from '@react-spring/web';
import CurrentCard from './CurrentCard';

function Game() {
    const { gameId } = useParams();
    const dispatch = useDispatch();
    const socket = useSelector((state) => selectSocketByNamespace(state, 'uno'));
    const socketLoaded = useSelector(selectSocketLoaded);
    const once = useRef(false);
    const onceSocketLoaded = useRef(false);
    const game = useSelector(selectGame);
    const player = useSelector(selectPlayer);
    const currentPlayer = useSelector(selectCurrentPlayer);

    function handleEndTurn() {
        socket.emit('end-of-turn');
    }
    function handleCallUno() {
        socket.emit('call-uno');
    }
    function handleUndo() {
        socket.emit('undo');
    }

    function handleTakeCard() {
        socket.emit('card-play', { card: 'new' });
    }

    useEffect(() => {
        if (gameId && !once.current) {
            once.current = true;
            dispatch(setupSockets(gameId, 'uno'));
        }
    }, [dispatch, gameId]);

    useEffect(() => {
        if (socketLoaded && !onceSocketLoaded.current) {
            onceSocketLoaded.current = true;
            socket.emit('update-user');
        }
    }, [socket, socketLoaded]);

    useEffect(() => {
        if (socketLoaded) {
            const playersUnsubListener = dispatch(
                socketListener('uno', 'players', (response) => {
                    console.log('players', response);
                    dispatch(setPlayers(response.players));
                    dispatch(setOfflinePlayers(response.offlinePlayers));
                })
            );
            const playerTurnChangeUnsubListener = dispatch(
                socketListener('uno', 'player-turn-change', (response) => {
                    dispatch(setCurrentPlayer(response.player));
                })
            );
            const gameUnsubListener = dispatch(
                socketListener('uno', 'game', (response) => {
                    dispatch(setGame(response.game));
                })
            );
            const playerUnsubListener = dispatch(
                socketListener('uno', 'player', (response) => {
                    console.log(response.player);
                    dispatch(setPlayer(response.player));
                })
            );
            return () => {
                playersUnsubListener();
                playerTurnChangeUnsubListener();
                gameUnsubListener();
                playerUnsubListener();
            };
        }
        return () => {};
    }, [dispatch, socketLoaded]);

    return (
        <div className={'flex'}>
            <div className={'h-screen'}>
                <Players />
            </div>
            <div className="game flex flex-col">
                <div className={'flex flex-col items-center w-full mt-20'}>
                    <div className={'flex justify-between'}>
                        <div className={'flex flex-col items-center cursor-default relative'}>
                            {game?.cards > 0 && (
                                <Typography
                                    variant={'caption'}
                                    className={'text-white absolute'}
                                    style={{ top: '-1rem' }}
                                >
                                    (+{game?.cards} cards)
                                </Typography>
                            )}
                            {game?.currentCard && <CurrentCard />}
                            <Typography variant={'caption'} className={'text-white'}>
                                Current card
                            </Typography>
                        </div>
                        <div className={'flex flex-col items-center'}>
                            <div
                                className={clsx(
                                    player?.user_id !== currentPlayer?.user_id
                                        ? 'opacity-50 cursor-default'
                                        : 'cursor-pointer'
                                )}
                                onClick={handleTakeCard}
                            >
                                <UnoCard card={'Wuno'} />
                            </div>
                            <Typography variant={'caption'} className={'text-white'}>
                                Draw a card
                            </Typography>
                        </div>
                    </div>
                    <ButtonGroup className={'mt-20'}>
                        {currentPlayer?.user_id === player?.user_id &&
                            game.latestActions &&
                            game.turnState === 2 &&
                            game.settings?.undoOption && (
                                <Button
                                    className={'py-6 px-24'}
                                    variant={'contained'}
                                    color={'warning'}
                                    onClick={handleUndo}
                                    disabled={!!player?.unoCalled}
                                >
                                    Undo
                                </Button>
                            )}
                        {player?.cards?.length === 1 && (
                            <Button
                                className={'py-6 px-24'}
                                variant={'contained'}
                                color={'error'}
                                onClick={handleCallUno}
                                disabled={!!player?.unoCalled}
                            >
                                UNO!
                            </Button>
                        )}
                        <Button
                            className={'py-6 px-24'}
                            variant={'contained'}
                            disabled={
                                currentPlayer?.user_id !== player?.user_id ||
                                (game.turnState !== 1 && game.turnState !== 2)
                            }
                            onClick={handleEndTurn}
                        >
                            End turn
                        </Button>
                    </ButtonGroup>
                </div>
                <div className={'mt-52 flex flex-col items-center'}>
                    <UnoCards />
                    <Typography variant={'caption'} className={'text-white'}>
                        Your cards
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default withReducer('unoGame', reducer)(Game);
