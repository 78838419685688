import {
    Alert,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { translateColor } from '../utilities';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { selectGrounds, selectPlayers } from './gameSlice';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import CustomHR from '../ui-components/CustomHR';
import { selectSocketByNamespace } from '../store/socketSlice';

const numberFormatter = new Intl.NumberFormat('da-DK');

export default function UpgradeGrounds({ onClose, card }) {
    const players = useSelector(selectPlayers);
    const user = useSelector(selectUser);
    const [selected, setSelected] = React.useState({});
    const socket = useSelector((state) => selectSocketByNamespace(state, 'matador'));

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    function handleUpgradeClose(_upgrade) {
        if (_upgrade) {
            socket.emit('ground-upgrade', { grounds: selected });
        }
        onClose(_upgrade);
    }
    function handleUpgradeSelect(ground, amount) {
        setSelected((oldState) => ({ ...oldState, [ground.number]: amount }));
    }

    const cardsSameColor = React.useMemo(() => {
        return playerSelf.cards.filter((ground) => ground.color === card.color);
    }, [playerSelf, card]);

    const upgradePrices = React.useMemo(() => {
        const _upgradePrices = {};
        cardsSameColor.forEach((ground) => {
            const currentHouses = ground.houses;
            const currentHotels = ground.hotels;
            _upgradePrices[ground.number] = {
                0: (0 - currentHouses - currentHotels * 5) * ground.buyHousePrice[0],
                1: (1 - currentHouses - currentHotels * 5) * ground.buyHousePrice[0],
                2: (2 - currentHouses - currentHotels * 5) * ground.buyHousePrice[0],
                3: (3 - currentHouses - currentHotels * 5) * ground.buyHousePrice[0],
                4: (4 - currentHouses - currentHotels * 5) * ground.buyHousePrice[0],
                5:
                    (4 - currentHouses - currentHotels * 4) * ground.buyHousePrice[0] +
                    (1 - currentHotels) * ground.buyHousePrice[1],
            };
        });

        return _upgradePrices;
    }, [cardsSameColor]);

    const selectedPrice = React.useMemo(() => {
        return _.sum(
            Object.entries(selected).map(([number, amount]) => {
                return upgradePrices[number][amount];
            })
        );
    }, [selected, upgradePrices]);

    const minHouses = React.useMemo(() => {
        return cardsSameColor.reduce((min, ground) => {
            if (selected[ground.number] !== null && selected[ground.number] !== undefined) {
                return Math.min(min, selected[ground.number]);
            }
            return Math.min(min, ground.houses + ground.hotels * 5);
        }, 5);
    }, [cardsSameColor, selected]);

    const maxHouses = React.useMemo(() => {
        return cardsSameColor.reduce((max, ground) => {
            if (selected[ground.number] !== null && selected[ground.number] !== undefined) {
                return Math.max(max, selected[ground.number]);
            }
            return Math.max(max, ground.houses + ground.hotels * 5);
        }, 0);
    }, [cardsSameColor, selected]);

    const disabled = React.useMemo(() => {
        if (selectedPrice * 100 > playerSelf.money) {
            return 'money';
        }
        if (maxHouses - minHouses > 1) {
            return 'amount';
        }
        return false;
    }, [maxHouses, minHouses, playerSelf.money, selectedPrice]);

    function handleSelectForAll(_amount) {
        setSelected(() => {
            const newState = {};
            cardsSameColor.forEach((ground) => {
                newState[ground.number] = _amount;
            });
            return newState;
        });
    }

    return (
        <Dialog open onClose={() => handleUpgradeClose()} maxWidth={'lg'} fullWidth>
            <div
                className={'pb-10'}
                style={{
                    backgroundColor: '#121a23',
                }}
            >
                <div className={'absolute left-10 top-10'}>
                    <div>
                        <Typography className={''}>
                            <span className={'font-bold'}>Din konto:</span>{' '}
                            {numberFormatter.format(playerSelf.money / 100)}
                            ,-
                        </Typography>
                        <Typography className={''}>
                            <span className={'font-bold'}>
                                Tilbage efter {selectedPrice < 0 ? 'salg' : 'køb'}:
                            </span>{' '}
                            <span
                                className={
                                    playerSelf.money / 100 - selectedPrice >= 0
                                        ? 'text-green-500'
                                        : 'text-red-500'
                                }
                            >
                                {numberFormatter.format(playerSelf.money / 100 - selectedPrice)},-
                            </span>
                        </Typography>
                    </div>
                </div>
                <DialogTitle className={'text-center'}>
                    Opgradering af de {translateColor(card.color).toLowerCase()} grunde
                </DialogTitle>
            </div>
            <CustomHR />
            <DialogContent sx={{ bgcolor: '#1B242E' }}>
                <Table size={'small'}>
                    <TableBody>
                        <TableRow>
                            <TableCell />
                            {_.range(0, 6).map((i) => (
                                <TableCell>
                                    <Button onClick={() => handleSelectForAll(i)}>
                                        Vælg for alle
                                    </Button>
                                </TableCell>
                            ))}
                        </TableRow>
                        {cardsSameColor.map((ground) => (
                            <TableRow>
                                <TableCell>
                                    <Typography>{ground.name}</Typography>
                                </TableCell>
                                {_.range(0, 6).map((i) => (
                                    <>
                                        {i === 0 && (
                                            <TableCell>
                                                <div
                                                    className={'flex flex-col justify-center w-76'}
                                                >
                                                    <Button
                                                        startIcon={<Icon>flag</Icon>}
                                                        className={'flex-col'}
                                                        variant={
                                                            (ground.houses === 0 &&
                                                                ground.hotels <= 0) ||
                                                            selected[ground.number] === 0
                                                                ? 'contained'
                                                                : 'outlined'
                                                        }
                                                        color={
                                                            selected[ground.number] === 0 &&
                                                            selected[ground.number] !==
                                                                ground.houses
                                                                ? 'warning'
                                                                : ground.houses === 0 &&
                                                                  ground.hotels <= 0
                                                                ? 'primary'
                                                                : 'inherit'
                                                        }
                                                        sx={{
                                                            '& .MuiButton-startIcon': {
                                                                textAlign: 'center',
                                                                margin: 0,
                                                                marginBottom: '6px',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            handleUpgradeSelect(ground, i)
                                                        }
                                                    >
                                                        Ingen huse
                                                        <Typography className={'text-center'}>
                                                            {upgradePrices[ground.number][i]}
                                                            ,-
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        )}
                                        {i >= 1 && i <= 4 && (
                                            <TableCell>
                                                <div
                                                    className={'flex flex-col justify-center w-76'}
                                                >
                                                    <Button
                                                        startIcon={
                                                            <Badge badgeContent={i}>
                                                                <Icon>home</Icon>
                                                            </Badge>
                                                        }
                                                        variant={
                                                            ground.houses === i ||
                                                            selected[ground.number] === i
                                                                ? 'contained'
                                                                : 'outlined'
                                                        }
                                                        color={
                                                            selected[ground.number] === i &&
                                                            selected[ground.number] !==
                                                                ground.houses
                                                                ? 'warning'
                                                                : ground.houses === i
                                                                ? 'primary'
                                                                : 'inherit'
                                                        }
                                                        className={'flex-col'}
                                                        sx={{
                                                            '& .MuiButton-startIcon': {
                                                                textAlign: 'center',
                                                                margin: 0,
                                                                marginBottom: '6px',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            handleUpgradeSelect(ground, i)
                                                        }
                                                    >
                                                        {i}x hus
                                                        <Typography className={'text-center'}>
                                                            {upgradePrices[ground.number][i]}
                                                            ,-
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        )}
                                        {i >= 5 && (
                                            <TableCell>
                                                <div
                                                    className={'flex flex-col justify-center w-76'}
                                                >
                                                    <Button
                                                        startIcon={<Icon>apartment</Icon>}
                                                        variant={
                                                            ground.hotels === 1 ||
                                                            selected[ground.number] === 5
                                                                ? 'contained'
                                                                : 'outlined'
                                                        }
                                                        color={
                                                            selected[ground.number] === 5 &&
                                                            ground.hotels !== 1
                                                                ? 'warning'
                                                                : ground.hotels === 1
                                                                ? 'primary'
                                                                : 'inherit'
                                                        }
                                                        className={'flex-col'}
                                                        sx={{
                                                            '& .MuiButton-startIcon': {
                                                                textAlign: 'center',
                                                                margin: 0,
                                                                marginBottom: '6px',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            handleUpgradeSelect(ground, i)
                                                        }
                                                    >
                                                        1x hotel
                                                        <Typography className={'text-center'}>
                                                            {upgradePrices[ground.number][i]}
                                                            ,-
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        )}
                                    </>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions sx={{ bgcolor: '#1B242E' }}>
                {disabled === 'amount' && (
                    <Alert variant={'filled'} severity={'error'} className={'mr-10 py-0'}>
                        Alle grunde skal have samme antal huse eller være forøget med 1 hus.
                    </Alert>
                )}
                {disabled === 'money' && (
                    <Alert variant={'filled'} severity={'error'} className={'mr-10 py-0'}>
                        Du har ikke råd til at købe dette.
                    </Alert>
                )}
                <Button
                    onClick={() => handleUpgradeClose(true)}
                    color={selectedPrice > 0 ? 'error' : selectedPrice < 0 ? 'success' : 'info'}
                    variant={'contained'}
                    disabled={!!disabled}
                >
                    {selectedPrice === 0 && 'Luk'}
                    {selectedPrice > 0 && `Køb for ${selectedPrice},-`}
                    {selectedPrice < 0 && `Sælg for ${Math.abs(selectedPrice)},-`}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
