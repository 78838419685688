import {
    Alert,
    Autocomplete,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Icon,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { translateColor } from '../utilities';
import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { removeSellOffer, selectGrounds, selectPlayers } from './gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/authSlice';
import CustomHR from '../ui-components/CustomHR';
import { selectSocketByNamespace } from '../store/socketSlice';
import { styled } from 'styled-components';

const numberFormatter = new Intl.NumberFormat('da-DK');

const SubTitle = styled.p`
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 700;
    color: #45f882;
    line-height: 1;
    margin: 0 0 7px;
`;

export default function SellGroundsToOther({ offer, onClose, defaultSelected }) {
    const players = useSelector(selectPlayers);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const socket = useSelector((state) => selectSocketByNamespace(state, 'matador'));
    const [selectedPlayer, setSelectedPlayer] = React.useState(null);
    const [moneyFrom, setMoneyFrom] = React.useState('0');
    const [moneyTo, setMoneyTo] = React.useState('0');
    const [cardsFrom, setCardsFrom] = React.useState(defaultSelected || []);
    const [cardsTo, setCardsTo] = React.useState([]);

    useEffect(() => {
        if (!offer) {
            return;
        }
        if (offer.own) {
            if (offer.cardsFrom) {
                setCardsFrom(offer.cardsFrom);
            }
            if (offer.cardsTo) {
                setCardsTo(offer.cardsTo);
            }
            if (offer.moneyFrom) {
                setMoneyFrom(offer.moneyFrom);
            }
            if (offer.moneyTo) {
                setMoneyTo(offer.moneyTo);
            }
            if (players) {
                setSelectedPlayer(players.find((player) => player.user_id === offer.to_user_id));
            }
        } else {
            if (offer.cardsFrom) {
                setCardsTo(offer.cardsFrom);
            }
            if (offer.cardsTo) {
                setCardsFrom(offer.cardsTo);
            }
            if (offer.moneyFrom) {
                setMoneyTo(offer.moneyFrom);
            }
            if (offer.moneyTo) {
                setMoneyFrom(offer.moneyTo);
            }
            if (players) {
                setSelectedPlayer(players.find((player) => player.user_id === offer.from_user_id));
            }
        }
    }, [offer, players]);

    const playerSelf = useMemo(() => {
        if (!players || !user?.discordUser?.id) {
            return null;
        }
        return players.find((player) => player.user_id === user.discordUser.id);
    }, [players, user]);

    function handleSellClose(_sell) {
        if (_sell) {
            socket.emit('sell-grounds-to-other-players', {
                selectedPlayer: selectedPlayer.user_id,
                moneyFrom,
                moneyTo,
                cardsFrom,
                cardsTo,
            });
        }
        onClose(_sell);
    }
    function handleOfferClose(buttonType) {
        if (buttonType === true) {
            dispatch(removeSellOffer(offer));
            socket.emit('sell-offer', {
                offerId: offer.id,
                type: 'accept',
            });
        } else if (buttonType === false) {
            if (offer.own) {
                socket.emit('sell-offer', {
                    offerId: offer.id,
                    type: 'self-deny',
                });
            } else {
                dispatch(removeSellOffer(offer));
                socket.emit('sell-offer', {
                    offerId: offer.id,
                    type: 'deny',
                });
            }
        }
        onClose();
    }
    const selectedPrice = React.useMemo(() => {
        return Number.parseInt(moneyFrom, 10) - Number.parseInt(moneyTo, 10);
    }, [moneyFrom, moneyTo]);

    const canSend = React.useMemo(() => {
        return selectedPrice !== 0 || cardsFrom.length > 0 || cardsTo.length > 0;
    }, [selectedPrice, cardsFrom, cardsTo]);

    const disabled = React.useMemo(() => {
        const moneyFromNumber = Number.parseInt(moneyFrom, 10);
        const moneyToNumber = Number.parseInt(moneyTo, 10);
        if (
            (moneyFromNumber && moneyFromNumber % 50 !== 0) ||
            (moneyToNumber && moneyToNumber % 50 !== 0)
        ) {
            return 'moneyNotDivisible';
        }
        if (moneyFromNumber > playerSelf?.money / 100) {
            return 'notEnoughMoney';
        }
        return false;
    }, [moneyFrom, moneyTo, playerSelf?.money]);

    function toggleCard(card, self) {
        if (self) {
            setCardsFrom((oldState) => {
                const copy = [...oldState];
                if (copy.includes(card.number)) {
                    copy.splice(copy.indexOf(card.number), 1);
                } else {
                    copy.push(card.number);
                }
                return copy;
            });
        } else {
            setCardsTo((oldState) => {
                const copy = [...oldState];
                if (copy.includes(card.number)) {
                    copy.splice(copy.indexOf(card.number), 1);
                } else {
                    copy.push(card.number);
                }
                return copy;
            });
        }
    }

    return (
        <Dialog open onClose={() => handleSellClose()} maxWidth={'lg'} fullWidth>
            <div
                className={'pb-10'}
                style={{
                    backgroundColor: '#121a23',
                }}
            >
                <div className={'absolute left-10 top-10'}>
                    <div>
                        <Typography className={''}>
                            <span className={'font-bold'}>Din konto:</span>{' '}
                            {numberFormatter.format(playerSelf.money / 100)}
                            ,-
                        </Typography>
                        <Typography className={''}>
                            <span className={'font-bold'}>
                                Tilbage efter {selectedPrice > 0 ? 'køb' : 'salg'}:
                            </span>{' '}
                            <span
                                className={
                                    playerSelf.money / 100 - selectedPrice >= 0
                                        ? 'text-green-500'
                                        : 'text-red-500'
                                }
                            >
                                {numberFormatter.format(playerSelf.money / 100 - selectedPrice)},-
                            </span>
                        </Typography>
                    </div>
                </div>
                {offer && offer.own && (
                    <DialogTitle className={'text-center'}>
                        Bud givet til {selectedPlayer?.username}
                    </DialogTitle>
                )}
                {offer && !offer.own && (
                    <DialogTitle className={'text-center'}>
                        Bud fra {selectedPlayer?.username}
                    </DialogTitle>
                )}
                {!offer && (
                    <DialogTitle className={'text-center'}>
                        Sælg grunde til en anden spiller
                    </DialogTitle>
                )}
                {!offer && (
                    <div className={'absolute right-10 top-10'}>
                        <div>
                            <Autocomplete
                                disablePortal
                                id="select-spiller"
                                options={players?.filter(
                                    (player) => player.user_id !== playerSelf.user_id
                                )}
                                getOptionLabel={(option) =>
                                    `[${translateColor(option.color).toLowerCase()}] ${
                                        option.username
                                    }`
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Valgt Spiller" />
                                )}
                                size={'small'}
                                noOptionsText={'Ingen spillere fundet'}
                                value={selectedPlayer}
                                onChange={(event, newValue) => {
                                    setSelectedPlayer(newValue);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <CustomHR />
            {!selectedPlayer && (
                <DialogContent sx={{ bgcolor: '#1B242E', height: '100%' }}>
                    <Typography className={'text-center pt-20'} variant={'subtitle1'}>
                        Vælg en spiller i højre hjørne
                    </Typography>
                </DialogContent>
            )}
            {selectedPlayer && (
                <DialogContent sx={{ bgcolor: '#1B242E', height: '100%' }}>
                    <div className={'flex justify-between h-full'}>
                        <div
                            className={
                                'flex flex-row justify-between border-0 border-r-2 border-solid w-1/2 h-full pr-20'
                            }
                            style={{ borderColor: '#45F882' }}
                        >
                            <div className={'w-full flex flex-col items-center'}>
                                <SubTitle>Følgende bliver givet til modspiller</SubTitle>
                                <Typography className={' text-white font-bold text-lg'}>
                                    Dig
                                </Typography>
                                <TextField
                                    label="Penge"
                                    id="me-money"
                                    fullWidth
                                    className={'w-full mt-10'}
                                    size={'small'}
                                    type={'number'}
                                    value={moneyFrom}
                                    onChange={(e) => setMoneyFrom(e.target.value)}
                                    disabled={!!offer}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">kr.</InputAdornment>
                                        ),
                                    }}
                                />
                                {playerSelf?.cards.map((card) => (
                                    <div className={'w-full mt-6'} key={'owncard-' + card.number}>
                                        <FormControlLabel
                                            className={clsx(
                                                'w-full',
                                                !cardsFrom.includes(card.number) && 'opacity-75'
                                            )}
                                            control={
                                                <Checkbox
                                                    checked={cardsFrom.includes(card.number)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                    }}
                                                />
                                            }
                                            onChange={() => toggleCard(card, true)}
                                            disabled={card.isPanted || !!offer}
                                            classes={{
                                                label: 'w-full',
                                            }}
                                            label={
                                                <div
                                                    className={
                                                        'flex items-center justify-between w-full'
                                                    }
                                                >
                                                    <div>
                                                        <Typography variant={'h6'}>
                                                            {card.name}
                                                        </Typography>
                                                        <Typography variant={'body2'}>
                                                            {translateColor(card.color)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant={'body2'}>
                                                                        Købspris:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography variant={'body2'}>
                                                                        {numberFormatter.format(
                                                                            card.buyPrice
                                                                        )}
                                                                        ,-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            {card.buyHousePrice && (
                                                                <tr>
                                                                    <td>
                                                                        <Typography
                                                                            variant={'body2'}
                                                                        >
                                                                            Værdi:
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography
                                                                            variant={'body2'}
                                                                        >
                                                                            {numberFormatter.format(
                                                                                card.buyPrice +
                                                                                    (card.houses +
                                                                                        card.hotels *
                                                                                            4) *
                                                                                        card
                                                                                            .buyHousePrice[0] +
                                                                                    card.hotels *
                                                                                        card
                                                                                            .buyHousePrice[1]
                                                                            )}
                                                                            ,-
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>
                                ))}
                                {_.range(0, playerSelf.unjailCards || 0).map((cardNumber) => (
                                    <div className={'w-full mt-6'}>
                                        <FormControlLabel
                                            className={clsx(
                                                'w-full',
                                                !cardsFrom.includes('unjail-' + cardNumber) &&
                                                    'opacity-75'
                                            )}
                                            control={
                                                <Checkbox
                                                    checked={cardsFrom.includes(
                                                        'unjail-' + cardNumber
                                                    )}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                    }}
                                                />
                                            }
                                            onChange={() =>
                                                toggleCard({ number: 'unjail-' + cardNumber }, true)
                                            }
                                            disabled={!!offer}
                                            classes={{
                                                label: 'w-full',
                                            }}
                                            label={
                                                <Typography variant={'h6'}>
                                                    Løsladelseskort{' '}
                                                    {playerSelf.unjailCards >= 2
                                                        ? `#${cardNumber + 1}`
                                                        : ''}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'flex flex-row justify-between w-1/2 ml-20'}>
                            <div className={'w-full flex flex-col items-center'}>
                                <SubTitle>Følgende modtager du af modspiller</SubTitle>
                                <Typography className={'text-white font-bold text-lg'}>
                                    {selectedPlayer.username}
                                </Typography>
                                <TextField
                                    label="Penge"
                                    id="me-money"
                                    fullWidth
                                    className={'w-full mt-10'}
                                    size={'small'}
                                    type={'number'}
                                    value={moneyTo}
                                    onChange={(e) => setMoneyTo(e.target.value)}
                                    disabled={!!offer}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">kr.</InputAdornment>
                                        ),
                                    }}
                                />
                                {selectedPlayer?.cards.map((card) => (
                                    <div className={'w-full mt-6'} key={'othercard-' + card.number}>
                                        <FormControlLabel
                                            className={clsx(
                                                'w-full',
                                                !cardsTo.includes(card.number) && 'opacity-75'
                                            )}
                                            control={
                                                <Checkbox
                                                    checked={cardsTo.includes(card.number)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                    }}
                                                />
                                            }
                                            onChange={() => toggleCard(card, false)}
                                            disabled={card.isPanted || !!offer}
                                            classes={{
                                                label: 'w-full',
                                            }}
                                            label={
                                                <div
                                                    className={
                                                        'flex items-center justify-between w-full'
                                                    }
                                                >
                                                    <div>
                                                        <Typography variant={'h6'}>
                                                            {card.name}
                                                        </Typography>
                                                        <Typography variant={'body2'}>
                                                            {translateColor(card.color)}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <Typography variant={'body2'}>
                                                                        Købspris:
                                                                    </Typography>
                                                                </td>
                                                                <td>
                                                                    <Typography variant={'body2'}>
                                                                        {numberFormatter.format(
                                                                            card.buyPrice
                                                                        )}
                                                                        ,-
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                            {card.buyHousePrice && (
                                                                <tr>
                                                                    <td>
                                                                        <Typography
                                                                            variant={'body2'}
                                                                        >
                                                                            Værdi:
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <Typography
                                                                            variant={'body2'}
                                                                        >
                                                                            {numberFormatter.format(
                                                                                card.buyPrice +
                                                                                    (card.houses +
                                                                                        card.hotels *
                                                                                            4) *
                                                                                        card
                                                                                            .buyHousePrice[0] +
                                                                                    card.hotels *
                                                                                        card
                                                                                            .buyHousePrice[1]
                                                                            )}
                                                                            ,-
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>
                                ))}
                                {_.range(0, selectedPlayer?.unjailCards || 0).map((cardNumber) => (
                                    <div className={'w-full mt-6'}>
                                        <FormControlLabel
                                            className={clsx(
                                                'w-full',
                                                !cardsTo.includes('unjail-' + cardNumber) &&
                                                    'opacity-75'
                                            )}
                                            control={
                                                <Checkbox
                                                    checked={cardsTo.includes(
                                                        'unjail-' + cardNumber
                                                    )}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                    }}
                                                />
                                            }
                                            onChange={() =>
                                                toggleCard({ number: 'unjail-' + cardNumber }, true)
                                            }
                                            classes={{
                                                label: 'w-full',
                                            }}
                                            disabled={!!offer}
                                            label={
                                                <Typography variant={'h6'}>
                                                    Løsladelseskort{' '}
                                                    {selectedPlayer?.unjailCards >= 2
                                                        ? `#${cardNumber + 1}`
                                                        : ''}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            )}
            <DialogActions sx={{ bgcolor: '#1B242E' }}>
                {disabled === 'moneyNotDivisible' && (
                    <Alert variant={'filled'} severity={'error'} className={'mr-10 py-0'}>
                        Beløbene skal være deleligt med 50.
                    </Alert>
                )}
                {disabled === 'notEnoughMoney' && (
                    <Alert variant={'filled'} severity={'error'} className={'mr-10 py-0'}>
                        Du har ikke råd til at gøre dette.
                    </Alert>
                )}
                {offer && offer.own && (
                    <>
                        <Button
                            onClick={() => handleOfferClose()}
                            color={'info'}
                            variant={'contained'}
                        >
                            Luk
                        </Button>
                        <Button
                            onClick={() => handleOfferClose(false)}
                            color={'warning'}
                            variant={'contained'}
                            disabled={!!disabled || !canSend}
                        >
                            Annuller bud
                        </Button>
                    </>
                )}
                {offer && !offer.own && (
                    <>
                        <Button
                            onClick={() => handleOfferClose()}
                            color={'info'}
                            variant={'contained'}
                        >
                            Luk
                        </Button>
                        <Button
                            onClick={() => handleOfferClose(false)}
                            color={'error'}
                            variant={'contained'}
                        >
                            Afvis bud
                        </Button>
                        <Button
                            onClick={() => handleOfferClose(true)}
                            color={'success'}
                            variant={'contained'}
                            disabled={!!disabled || !canSend}
                        >
                            Accepter bud
                        </Button>
                    </>
                )}
                {!offer && (
                    <Button
                        onClick={() => handleSellClose(true)}
                        color={canSend ? 'error' : 'info'}
                        variant={'contained'}
                        disabled={!!disabled}
                    >
                        {!canSend && 'Luk'}
                        {canSend && `Send bud`}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
